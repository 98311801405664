import {
  de_default
} from "./chunk-2ICCSDSS.mjs";
import {
  en_default
} from "./chunk-BTMUZKDH.mjs";
import {
  fr_default
} from "./chunk-5AUBRJMM.mjs";

// src/frontend/index.ts
var frontend_default = { en: en_default, fr: fr_default, de: de_default };

export {
  frontend_default
};
