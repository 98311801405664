// src/frontend/de.json
var funds = {
  description: {
    distribution: {
      label: {},
      value_string: "Bereich",
      label_string: "Kanton"
    },
    tile: {
      distribution: "Verteilung",
      administrative: "Administrativ",
      designation: "Bezeichnung",
      stock: "Aktie",
      composition: "Komposition"
    },
    allocation: {},
    overview: {},
    ranking: "Fond-Ranking:",
    composition: {
      title: "Komposition"
    },
    title: "<text>Beschreibung</text>",
    identification: {
      title: "Identifikation"
    }
  },
  composition: {
    building_groups_nb: "Anzahl der Baugruppen",
    semi_annual_report_date: "Letzter Bericht (halbj\xE4hrlich)",
    annual_report_date: "Letzter Bericht (j\xE4hrlich)",
    report_date: "Letzter Bericht",
    total_area: "Gesamtfl\xE4che",
    lots_nb: "Anzahl der Grundst\xFCcke",
    lot_area: "Plotbereich",
    office_area: "Gesamtb\xFCrofl\xE4che",
    fund_age: "Alter des Immobilienportfolios",
    residential_area: "Gesamtwohnfl\xE4che",
    industrial_area: "Gesamtindustriefl\xE4che",
    commercial_area: "Gesamtfl\xE4che f\xFCr Gewerbe",
    buildings_nb: "Anzahl der Geb\xE4ude",
    report_age: "Alter des aktuellen Immobilienberichts"
  },
  financials: {
    income_statement: {},
    accounting: {
      income_statement: {},
      balance_sheet: {}
    },
    balance_sheet: {},
    rental_income: {
      r_other_net_rent: "Andere",
      r_plots_net_rent: "Plots",
      computed_by_m2: {
        title: "Miet Einnahmen pro m\xB2 berechnet"
      },
      computed: {
        title: "Berechnete Mieteinnahmen"
      },
      c_net_rent_q3: "Q3",
      c_net_rent_median: "Median",
      c_mixed_net_rent: "Gemischt",
      c_total_net_rent: "Gesamt",
      c_resiential_net_rent: "Wohn-",
      c_commercial_net_rent: "Kommerziell",
      c_net_rent_q1: "Q1",
      estimated_by_m2: {
        title: "Mietrendite pro m\xB2 gesch\xE4tzt"
      },
      reported_by_m2: {
        title: "Mietrendite pro m\xB2 angegeben"
      },
      estimated: {
        title: "Gesch\xE4tzte Mieteinnahmen"
      },
      r_net_rent_median: "Median",
      r_net_rent_q1: "Q1",
      r_net_rent_q3: "Q3",
      r_total_net_rent: "Total",
      r_mixed_net_rent: "Gemischt",
      r_resiential_net_rent: "Wohn-",
      r_commercial_net_rent: "Kommerziell",
      reported: {
        title: "Vermietungseinkommen gemeldet"
      },
      title: "Mieteinnahmen"
    },
    portfolio: {
      r_other_cost_price: "Andere",
      r_plots_cost_price: "Plots",
      r_other_market_value: "Andere",
      r_plots_market_value: "Plots",
      computed_market_value_by_m2: {
        title: "Marktwert pro m\xB2"
      },
      computed_market_value: {
        title: "Marktwert von Geb\xE4uden"
      },
      c_cost_price_by_m2: {
        title: "Kostenpreis pro m\xB2"
      },
      c_cost_price: {
        title: "Kostenpreis von Geb\xE4uden"
      },
      r_cost_price_q3: "Q3",
      r_commercial_market_value: "Gewerblich",
      c_market_value_q1: "Q1",
      c_market_value_median: "Median",
      c_market_value_q3: "Q3",
      c_mixed_market_value: "Gemischt",
      c_total_market_value: "Gesamt",
      c_commercial_market_value: "Kommerziell",
      c_market_value_by_m2: {
        title: "Marktwert pro m\xB2 gesch\xE4tzt"
      },
      c_residential_market_value: "Wohn-",
      r_market_value_median: "Median",
      c_market_value: {
        title: "Marktwert von Geb\xE4uden gesch\xE4tzt"
      },
      r_market_value_by_m2: {
        title: "Marktwert pro m\xB2 gemeldet"
      },
      r_total_market_value: "Total",
      r_market_value_q1: "Q1",
      r_mixed_market_value: "Gemischt",
      r_residential_market_value: "Wohnbereich",
      r_market_value: {
        title: "Marktwert der gemeldeten Geb\xE4ude"
      },
      r_cost_price_median: "Median",
      r_market_value_q3: "Q3",
      r_mixed_cost_price: "Gemischt",
      r_cost_price_by_m2: {
        title: "Kostenpreis pro m\xB2 berichtet"
      },
      r_cost_price_q1: "Q1",
      r_total_cost_price: "Total",
      r_cost_price: {
        title: "Kostenpreis der gemeldeten Geb\xE4ude"
      },
      r_residential_cost_price: "Wohn-",
      r_commercial_cost_price: "Kommerziell",
      title: "Portfoliowert"
    },
    market_value: {
      title: "Marktwert"
    },
    cost_price: {
      title: "Kostenpreis"
    },
    evolution: {
      chart: {
        title: "Diagramm"
      },
      title: "Evolution"
    },
    metrics: {
      balance_sheet: {
        chart_title: "Bilanz"
      },
      income_statement: {
        chart_title: "Einkommensbericht",
        title: "Einkommensstatistik Kennzahlen"
      },
      r_estimated_liquidation_taxes: "Gesch\xE4tzte Liquidationssteuern",
      c_weighted_average_cost_of_debt: "Gewichteter durchschnittlicher Zinssatz",
      c_earnings_per_share: "Gewinn pro Aktie",
      c_weighted_average_maturity: "Gewichtete durchschnittliche Laufzeit",
      c_current_liabilities: "Aktuelle Verbindlichkeiten",
      c_revenue_per_share: "Erl\xF6s pro Aktie",
      c_current_ratio: "Aktuelle Verh\xE4ltnis",
      c_operating_profit: "Betriebsergebnis",
      c_ebit_margin: "EBIT-Marge",
      c_loan_to_value: "Beleihungswert",
      c_debt_ratio: "Verschuldungsquote",
      c_total_cash_per_share: "Gesamtbetrag an Bargeld pro Aktie",
      liquidity: {
        title: "Liquidit\xE4tskennzahlen"
      },
      c_payout_ratio: "Auszahlungsverh\xE4ltnis",
      c_price_to_book_value: "Preis-Buchwert-Verh\xE4ltnis",
      c_net_initial_yield: "Nettoanfangsrendite",
      c_pe_ratio: "P/E-Verh\xE4ltnis",
      c_roa: "ROA",
      c_roce: "ROCE",
      c_roe: "ROE",
      five_years_avg_dividend_yield: "5-Jahres-Durchschnittsdividendenrendite",
      r_payout_ratio: "Auszahlungsverh\xE4ltnis",
      c_agio_at_year_end: "Agio zum Jahresende",
      quantitative_valuation_ratios: {
        title: "Quantitative Bewertungsverh\xE4ltnisse"
      },
      r_total_dividend: "Gesamtdividende",
      m_dividend_yield: "Dividendenrendite",
      r_dividend_yield: "Dividendenrendite",
      r_roic: "ROIC",
      r_performance: "Leistung",
      dividends: {
        title: "Dividenden"
      },
      r_dividend_from_capital_gains: "Dividende aus Kapitalgewinnen",
      r_wault: "WAULT",
      r_dividend_from_direct_rental_income: "Dividende aus direkten Mieteinnahmen",
      r_dividend_from_indirect_rental_income: "Dividende aus indirekten Mieteinnahmen",
      r_ebit_margin: "EBIT-Marge",
      r_roi: "ROI",
      ratios: {
        title: "Verh\xE4ltnisse"
      },
      r_roe: "ROE",
      r_debt_ratio: "Verschuldungsquote",
      r_rental_losses: "Vermietungsverluste",
      title: "Finanzkennzahlen"
    },
    side_menu: {
      hover_label: "Variation"
    },
    report: {
      semi_annual: "halbj\xE4hrlich",
      annual: "j\xE4hrlich"
    },
    stock: {
      evolution: {
        title: "Evolution"
      },
      hist_volatility_250_days: "Historische Volatilit\xE4t 1 Jahr",
      sharpe_ratio: "Sharpe-Verh\xE4ltnis",
      hist_volatility_180_days: "Historische Volatilit\xE4t 6 Monate",
      alpha: "Alpha (5Y monatlich)",
      hist_volatility_30_days: "Historische Volatilit\xE4t 1-Monat",
      r2: "R\xB2",
      beta: "Beta (5Y monatlich)",
      hist_volatility_90_days: "Historische Volatilit\xE4t 3 Monate",
      three_months_avg_volume: "Durchschnittliches Volumen 3-Monat",
      five_days_avg_volume: "Durchschnittliches Volumen 5-Tage",
      six_months_avg_volume: "Durchschnittliches Volumen 6 Monate",
      one_year_avg_volume: "Durchschnittliches Volumen 1-Jahr",
      one_month_avg_volume: "Durchschnittliches Volumen 1-Monat",
      risk: {
        title: "Risikostatistiken"
      },
      unit_outstanding: "Ausstehende Aktien",
      turnover: "Umsatz",
      volume: "Letzter Band",
      fifty_two_weeks_low: "52-Wochen-Tief",
      trade: {
        title: "Handelsstatistiken"
      },
      fifty_two_weeks_high: "52-Wochen-Hoch",
      five_years_return: "5 Jahre",
      six_months_return: "6-Monat",
      one_year_return: "1-Jahr",
      three_years_return: "3-Jahre",
      three_months_return: "3-Monat",
      one_month_return: "1-Monat",
      ytd_net_return: "Jahr-bis-dato (YTD)",
      qtd_net_return: "Quartal-bis-Datum (QTD)",
      mtd_net_return: "Monat-bis-datum (MTD)",
      history: {
        title: "Aktienkursverlauf"
      },
      title: "Aktienperformance"
    },
    stock_performance: {
      title: "Aktienperformance"
    },
    fees: {
      r_max_share_buyback_commissions: "Aktienr\xFCckkaufkommission",
      r_max_share_issue_commissions: "Aktienemissionsgeb\xFChr",
      r_max_construction_and_works_fees: "Bau und Arbeiten",
      r_max_property_management_fees: "Immobilienverwaltungsgeb\xFChren",
      r_max_custodian_bank_fees: "Verwahrstellengeb\xFChren",
      r_max_purchase_and_sale_fees: "Kauf und Verkauf von Immobilien",
      r_ter_mv: "TERrefMV",
      maximum: {
        title: "Maximale Geb\xFChren"
      },
      r_ter_nav: "TERrefNAV",
      r_max_management_fees: "Managementgeb\xFChren",
      r_ter_gav: "TERrefGAV",
      r_share_issue_commissions: "Aktienausgabekommission",
      r_property_management_fees: "Verwaltungsgeb\xFChren f\xFCr Immobilien",
      r_construction_and_works_fees: "Bau und Arbeiten",
      r_custodian_bank_fees: "Verwahrstellengeb\xFChren",
      r_management_fees: "Managementgeb\xFChren",
      r_share_buyback_commissions: "Aktienr\xFCckkaufkommission",
      r_purchase_and_sale_fees: "Kauf und Verkauf von Immobilien",
      effective: {
        title: "Effektive Geb\xFChren"
      },
      title: "Geb\xFChren"
    },
    performance: {
      title: "Leistung",
      r_tga_before_distribution: "TGA vor der Verteilung",
      r_tga_after_distribution: "TGA nach der Verteilung",
      r_market_cap: "Marktkapitalisierung",
      r_tna_after_distribution: "TNA nach der Verteilung",
      c_total_agio_at_year_end: "Agio",
      r_tna_before_distribution: "TNA vor der Verteilung",
      m_market_cap: "Marktkapitalisierung",
      current_total_value: {
        title: "Aktueller Gesamtwert"
      },
      r_agio_at_year_end: "Agio",
      c_nav_after_distribution: "NAV nach Verteilung",
      c_total_current_agio_after_distribution: "Gesamtagio nach Verteilung",
      year_end_total_value: {
        title: "Gesamtwert am Jahresende"
      },
      c_total_current_agio_compounded: "Gesamtes aufgelaufenes Agio",
      c_tna_compounded: "TNA zusammengesetzt",
      c_tga_compounded: "TGA compounded",
      c_nav_compounded: "NAV zusammengesetzt",
      c_gav_compounded: "GAV zusammengesetzt",
      r_nav_before_distribution: "NAV vor der Verteilung",
      year_end_value_per_share: {
        title: "Wert pro Aktie zum Jahresende"
      },
      c_gav_after_distribution: "GAV nach Verteilung",
      r_share_price: "Aktienkurs",
      r_gav_before_distribution: "GAV vor der Verteilung",
      m_dividend_yield: "Dividendenrendite",
      c_current_agio_after_distribution: "Agio nach Verteilung",
      c_current_agio_compounded: "Agio verzinst",
      current_value_per_share: {
        title: "Aktueller Wert pro Aktie"
      },
      m_share_price: "Aktienkurs"
    },
    debts: {
      title: "Schuldenstruktur"
    },
    tile: {
      measures: "Bewertungsma\xDFnahmen",
      dividends: "Dividenden",
      trade: "Handelsstatistiken",
      risk_statistics: "Risiko-Statistiken",
      stock_history: "Aktienkursgeschichte",
      rental_income: "Mieteinkommen",
      key_performance: "Schl\xFCsselleistung",
      key_financials_per_share: "Finanzielle Leistung (pro Aktie)",
      evolution: "Evolution",
      key_financials: "Finanzielle Leistung (gesamt)",
      return: "R\xFCckkehr",
      charges: "Mietergeb\xFChren",
      losses: "Verluste",
      fees: "Geb\xFChren"
    },
    trading: {
      title: "Handelsinformationen"
    },
    highlights: {
      title: "Finanzielle H\xF6hepunkte"
    },
    revenue: {
      title: "Einnahmen"
    },
    charges: {
      title: "Mietergeb\xFChren"
    },
    title: "Finanzen"
  },
  accounting: {},
  debt: {
    title: "Schulden"
  },
  overview: {
    net_initial_yield: "Nettoanfangsrendite",
    esg_rating: "PRESS-Bewertung",
    ter_gav: "TERrefGAV",
    roa: "ROA",
    market_cap: "Marktkapitalisierung",
    total_co2: "CO\u2082",
    total_net_assets: "TNA",
    net_rent: "Mieteinnahmen",
    debt_ratio: "Verschuldungsquote",
    agio: "Agio"
  },
  environmental: {
    share: {
      r_coverage_share_fossil: "Abdeckung",
      r_share_fossil: "Teilen Sie Fossilien",
      coverage: "Abdeckungsrate",
      non_fossil: "Teilen Sie nicht-fossile",
      fossil: "Fossil teilen"
    },
    co2: {
      r_coverage_co2: "Deckung",
      title: "CO\u2082-Emissionen",
      co2_scope_1_2_per_m2: {
        description: "Die CO\u2082-Emissionen werden berechnet, indem die Energieintensit\xE4t mit dem REIDA-Koeffizienten f\xFCr jedes Geb\xE4ude multipliziert wird. Die gesamten CO\u2082-Emissionen f\xFCr das Fahrzeug werden dann mit einem gewichteten Durchschnitt der Geb\xE4ude berechnet, wobei jedes Geb\xE4ude entsprechend seiner Heizfl\xE4che gewichtet wird.",
        key: "CO\u2082 (Bereich 1 + 2)"
      },
      r_co2: "Gesamte CO\u2082-Emissionen",
      total_co2_scope_1_2_33: "Gesamt CO\u2082 (Bereich 1 + 2 + 3.3)",
      total_co2_scope_1_2: "Total CO\u2082 (Bereich 1 + 2)",
      co2_scope_33_per_m2: "CO\u2082 (Scope 3.3)",
      co2_scope_2_per_m2: "CO\u2082 (Scope 2)",
      co2_scope_1_per_m2: "CO\u2082 (Bereich 1)",
      co2_scope_1_2_33_per_m2: "CO\u2082 (Bereich 1 + 2 + 3.3)",
      r_co2_per_m2: "CO\u2082-Emissionen"
    },
    idc: {
      r_coverage_idc: "Deckung",
      idc_per_m2: {
        description: "Die Energieintensit\xE4t misst den gesch\xE4tzten Energieverbrauch pro Quadratmeter Heizfl\xE4che \xFCber ein Jahr f\xFCr jedes Geb\xE4ude im Investitionsvehikel. Die gesamte Energieintensit\xE4t f\xFCr das Vehikel wird dann mit einem gewichteten Durchschnitt der Geb\xE4ude berechnet, wobei jedes Geb\xE4ude entsprechend seiner Heizfl\xE4che gewichtet wird.",
        key: "Energieintensit\xE4t"
      },
      title: "Energiest\xE4rke",
      r_idc: "Gesamtenergie",
      total_idc: "Gesamtenergie",
      r_idc_per_m2: "Energiemenge"
    },
    tile: {
      share: "Energiesteuer",
      idc_and_co2: "Energieintensit\xE4t & CO\u2082",
      environmental_risks: "Umwelt Risiken",
      energy_source: "Energiquelle",
      solar: "Solar",
      climate_risk_index: "Klimarisikoindex"
    },
    evolution: {
      chart: {
        title: "Diagramm"
      },
      title: "Evolution"
    },
    climate: {
      risk: {
        description: "Der Klimarisikoindex basiert auf der Exposition von Geb\xE4uden innerhalb von Anlagevehikeln gegen\xFCber verschiedenen Umweltgefahren. Jedes Risiko hat im Index ein gleiches Gewicht, w\xE4hrend die Geb\xE4ude nach ihrer Heizfl\xE4che f\xFCr die Aggregation auf der Ebene der Anlagevehikel gewichtet werden."
      }
    },
    total_estimated_idc: "Gesamte Energieintensit\xE4t",
    estimated_idc_per_m2: "Energiest\xE4rke pro m\xB2",
    total_estimated_co2_emission: "Gesamt CO\u2082 (gesch\xE4tzt)",
    estimated_co2_emission_per_m2: "CO\u2082 pro m\xB2 (gesch\xE4tzt)",
    press_score: "Umwelt-PRESS-Score:",
    solar_existing_per_m2: "Installierte Solarleistung pro m\xB2",
    title: "Umwelt",
    building_with_solar_existing: "Geb\xE4ude mit Solarpanels",
    energy: {
      title: "Energie"
    },
    hail_hazard_10_years: "Hagelgr\xF6\xDFe",
    overbank_sedimentation: "\xDCberflutungssedimentation",
    risk: {
      title: "Risiko"
    },
    landslides: "Landschaften",
    floods_50_years: "\xDCberschwemmungen",
    solar_existing: "Installierte Solarenergie",
    sia261: "SIA261",
    rockfalls: "Felsst\xFCrze",
    debris_flow: "Tr\xFCmmerstrom",
    avalanches: "Lawinen",
    solar_potential: "Gesamtes solares Potenzial"
  },
  social: {
    transport_accessibility: {
      title: "Zug\xE4nglichkeit",
      transport_accessibility: {
        description: "Durchschnitt des Zug\xE4nglichkeitswerts durch \xF6ffentliche Verkehrsmittel gem\xE4\xDF dem ARE f\xFCr jedes Geb\xE4ude",
        key: "Zug\xE4nglichkeit mit \xF6ffentlichen Verkehrsmitteln",
        title: "Mit dem Zug"
      },
      road_accessibility: {
        description: "Durchschnitt des Zug\xE4nglichkeitswerts nach Stra\xDFe gem\xE4\xDF der ARE f\xFCr jedes Geb\xE4ude",
        key: "Zug\xE4nglichkeit \xFCber Stra\xDFen",
        title: "Auf der Stra\xDFe"
      }
    },
    well_being: {
      title: "Wohlbefinden",
      green_density: {
        description: "Die gr\xFCne Dichte enth\xE4lt einen Durchschnitt des Anteils der umliegenden Hektar, die einen Gro\xDFteil der Gr\xFCnfl\xE4che f\xFCr jedes Geb\xE4ude gem\xE4\xDF der Fl\xE4chennutzung (NOAS04) enthalten.",
        key: "Gr\xFCne Dichte",
        title: "Gr\xFCnfl\xE4chen"
      },
      public_utility_density: {
        description: "Dies misst den Anteil \xF6ffentlicher Versorgungsgeb\xE4ude (Krankenh\xE4user, Altenpflegeeinrichtungen, Schulen\u2026) im Portfolio des Fahrzeugs",
        key: "Anteil der \xF6ffentlichen Versorgungsgeb\xE4ude",
        title: "\xD6ffentliche Versorgungsgeb\xE4ude"
      }
    },
    density: {
      title: "Dichte",
      job_density: {
        key: "Jobdichte",
        description: "Die Besch\xE4ftigungsdichte misst die durchschnittliche Besch\xE4ftigungsdichte in den umliegenden Hektar, gem\xE4\xDF STATENT, pro Geb\xE4ude",
        title: "Jobdichte"
      },
      population_density: {
        description: "Die Bev\xF6lkerungsdichte misst die durchschnittliche Bev\xF6lkerungsdichte in den umliegenden Hektar, gem\xE4\xDF STATPOP, pro Geb\xE4ude",
        key: "Bev\xF6lkerungsdichte",
        title: "Bev\xF6lkerungsdichte"
      }
    },
    accessibility: {
      primary_education: "Primarbildung",
      shops: "Gesch\xE4fte",
      cultural_venues: "Kulturelle Veranstaltungsorte",
      transport_train: "Zug",
      transport_bus: "Bus",
      restaurants: "Restaurants",
      score: "Ergebnis",
      early_years: "Fr\xFChe Jahre",
      secondary_education: "Sekundarbildung",
      aged_care: "Altenpflege",
      healthcare: "Gesundheitswesen",
      mean_healthcare: "Gesundheitsversorgung",
      mean_aged_care: "Altenpflege",
      mean_secondary_education: "Sekundarschulbildung",
      mean_early_years: "Fr\xFChe Jahre",
      title: "Barrierefreiheit",
      mean_score: "Ergebnis",
      mean_restaurants: "Restaurants",
      mean_transport_bus: "Bus",
      mean_cultural_venues: "Kulturelle Einrichtungen",
      mean_transport_train: "Zug",
      mean_shops: "Gesch\xE4fte",
      mean_primary_education: "Grundbildung"
    },
    tile: {
      accessibility_others: "Andere (Durchschnittliche Entfernung)",
      accessibility_transportation: "\xD6ffentliche Verkehrsmittel (durchschnittliche Entfernung)",
      accessibility_education: "Gesundheit und Bildung (durchschnittliche Entfernung)",
      accessibility_score: "Zug\xE4nglichkeit",
      accessibility_to_services: "Zug\xE4nglichkeit zu Dienstleistungen"
    },
    press_score: "Sozial PRESS Punktestand:",
    promiscuity: {
      per_100_m2: "Pro 100 m\xB2",
      departure_per_inhabitant: "Abreise pro Einwohner",
      title: "Bev\xF6lkerungsdichte",
      per_bedroom: "Pro Schlafzimmer",
      arrival_per_inhabitant: "Ankunft pro Einwohner",
      tile: {
        migration: "Migration",
        occupancy: "Belegung"
      }
    },
    demographic: {
      tile: {
        ages: "Alter",
        gender: "Geschlecht",
        foreigners: "Ausl\xE4nder"
      },
      title: "Demografisch"
    },
    title: "Sozial"
  },
  governance: {
    tile: {
      minergie: "Minergie",
      press_score: "DRUCK \u2013 \xD6ffentliche Immobiliennachhaltigkeitsbewertungen",
      certification: "Zertifizierung",
      auditors: "Pr\xFCfer",
      valuation_expert: "Bewertungsexperten",
      property_management: "Immobilienverwaltung",
      ownership: "Eigentum"
    },
    press_score: "Governance PRESS-Score:",
    minergie_buildings: "Prozentsatz der Minergie-Geb\xE4ude",
    ranking: "Ranking",
    esg: "ESG",
    governance: "Governance",
    social: "Sozial",
    environmental: "Umwelt",
    custodian_bank: "Depotbank",
    director: "Fondsdirektor",
    manager: "Fondsmanager",
    certificates_rating: {
      title: "Zertifikate und Bewertung"
    },
    organisation: {
      title: "Organisation"
    },
    title: "Governance"
  },
  environment: {
    energy_distribution: {
      value_string: "Beheizter Bereich",
      label_string: "Heizquelle"
    }
  },
  transactions: {
    value: {
      purchase: "gekauft",
      sale: "verkauft"
    },
    title: "Transaktionen"
  },
  page: {
    title: "Alle Schweizer Fonds"
  },
  performance: {
    total_expense_ratio_ref_nav: "Gesamtkostenquote (TER_NAV)",
    return_on_capital_employed: "ROCE",
    return_on_asset: "ROA",
    five_y_avg_dividend_yield: "5-Jahres-Durchschnittsrendite",
    dividend_yield: "Dividendenrendite",
    dividend: "Letzte Dividende",
    turnover: "Umsatz",
    volume: "Durchschnittliches Volumen",
    fifty_two_w_low: "52-Wochen-Tief",
    fifty_two_w_high: "52-Wochen-Hoch",
    three_y_return: "3 Jahre",
    one_y_return: "1-Jahr",
    three_m_return: "3-Monat",
    one_m_return: "1-Monat",
    hist_volatility_250_days: "Historische Volatilit\xE4t 1 Jahr",
    hist_volatility_180_days: "Historische Volatilit\xE4t 6 Monate",
    hist_volatility_90_days: "Historische Volatilit\xE4t 3 Monate",
    hist_volatility_30_days: "Historische Volatilit\xE4t 1 Monat",
    r2: "R\xB2",
    beta: "Beta (5Y monatlich)",
    alpha: "Alpha (5Y Monatlich)",
    volume_suffix: "Einheiten",
    wault_suffix: "Jahre",
    mtd_net_return: "Monat bis heute (MTD)",
    debt_financing_ratio: "Verschuldungsquote",
    total_gross_assets: "Gesamtbruttoverm\xF6gen",
    return_on_equity: "Eigenkapitalrendite (ROE)",
    distribution_yield: "Vertriebsertrag",
    rent_default_rate: "Vermietungsverluste",
    ebit_margin: "EBIT-Marge",
    yearly_management_fee: "Managementgeb\xFChr",
    total_expense_ratio_ref_mv: "Gesamtkostenquote (TER_MV)",
    payout_ratio: "Auszahlungsverh\xE4ltnis",
    relative_agio: "Agio",
    total_net_assets: "Gesamte Nettoverm\xF6gen",
    market_capitalization: "Marktkapitalisierung",
    qtd_net_return: "Quartal bis heute (QTD)",
    total_expense_ratio_ref_gav: "Gesamtkostenquote (TER_GAV)",
    return_on_investment: "Rendite auf Investition (ROI)",
    ytd_net_return: "Jahr bis heute (YTD)",
    wault_label: "Gewichtete durchschnittliche laufende Mietdauer (WAULT)",
    return_on_invested_capital: "Rendite auf investiertes Kapital (ROIC)"
  },
  noright: {
    button: "Kontaktieren Sie den Kundenbetreuer",
    details: "Das Institutionsmodul zeigt umfassende Informationen \xFCber eine Vielzahl von Schweizer Immobilienunternehmen.",
    subtitle: "Dieses Feature erfordert einen umfassenderen Plan.",
    title: "Institutionen"
  },
  financials_per_share: {
    share_market_price: "Aktienkurs",
    share_class_gav: "Bruttoverm\xF6genswert (GAV)",
    last_distribution: "Letzter Verteilungstermin",
    distribution_value: "Verteilungswert",
    share_class_nb: "Ausstehende Aktien",
    share_class_nav: "Nettoinventarwert (NAV)"
  },
  designation: {
    valor_no: "Wertnummer",
    bloomberg_ticker: "Bloomberg Ticker",
    instrument_name: "Name des Instruments",
    ticker: "Allgemeiner Name",
    isin: "ISIN",
    currency: "W\xE4hrung"
  },
  stock: {
    last_distribution: "Letzter Verteilungstermin",
    distribution_frequency: "Verteilungsh\xE4ufigkeit",
    units_class: "Klasse der Einheiten",
    units_nb: "Ausstehende Aktien",
    year_end: "Jahresenddatum",
    distribution_date: "Verteilerdatum",
    launch_date: "Ver\xF6ffentlichungsdatum"
  },
  administrative: {
    name: "Aktienregister",
    benchmark: "Benchmark",
    manager: "Fondsmanager",
    custodian_bank: "Depotbank",
    director: "Fondsleiter",
    domicile: "Domicil des Fonds",
    benchmark_bloomberg_ticker: "Benchmark Ticker"
  },
  assets: {
    title: "Inventar"
  }
};
var panel_selection = {
  option: {},
  title: {}
};
var units = {
  r_market_value_per_m2: "CHF/m\xB2",
  r_cost_price_per_m2: "CHF/m\xB2",
  r_real_rent_per_m2: "CHF/m\xB2",
  r_other_net_rent: "CHF",
  r_plots_net_rent: "CHF",
  r_unrealised_gains_and_losses_including_var_est_liq_tax: "CHF",
  r_coverage_share_fossil: "%",
  r_share_fossil: "%",
  r_coverage_idc: "%",
  r_coverage_co2: "%",
  total_co2_scope_1_2_33: "kg/Jahr",
  co2_scope_33_per_m2: "kg/m\xB2/Jahr",
  co2_scope_1_2_33_per_m2: "kg/m\xB2/Jahr",
  co2_scope_1_2_per_m2: "kg/m\xB2/Jahr",
  idc_per_m2: "kWh/m\xB2/Jahr",
  share: {
    coverage: "%",
    non_fossil: "%",
    fossil: "%"
  },
  total_co2_scope_1_2: "kg/Jahr",
  total_co2_scope_1_2_3: "kg/Jahr",
  total_idc: "kWh/Jahr",
  co2_scope_1_per_m2: "kg/m\xB2/Jahr",
  co2_scope_2_per_m2: "kg/m\xB2/Jahr",
  total_co2_scope_1: "kg/Jahr",
  total_co2_scope_2: "kg/Jahr",
  total_co2_scope_33: "kg/Jahr",
  co2_scope_1_2_3_per_m2: "kg/m\xB2/Jahr",
  r_co2: "kg/Jahr",
  r_co2_per_m2: "kg/m2/Jahr",
  r_idc: "kWh/Jahr",
  r_idc_per_m2: "kWh/m2/Jahr",
  transport_accessibility: "pts",
  road_accessibility: "pts",
  green_density: "%",
  public_utility_density: "%",
  job_density: "FTE/ha",
  population_density: "ppl./ha",
  vwap: "CHF",
  c_market_value: "CHF",
  c_net_rent: "CHF",
  r_gross_yield: "%",
  r_real_rent: "CHF",
  r_target_rent: "CHF",
  r_cost_price: "CHF",
  r_rental_space: "m\xB2",
  stock_volume: "Einheiten",
  r_market_value: "CHF",
  six_months_avg_volume: "Einheiten",
  five_days_avg_volume: "Einheiten",
  one_year_avg_volume: "Einheiten",
  three_months_avg_volume: "Einheiten",
  one_month_avg_volume: "Einheiten",
  five_years_return: "%",
  one_year_return: "%",
  fifty_two_weeks_low: "CHF",
  six_months_return: "%",
  three_years_return: "%",
  unit_outstanding: "Einheiten",
  turnover: "CHF",
  qtd_net_return: "%",
  mtd_net_return: "%",
  fifty_two_weeks_high: "CHF",
  r_cost_price_q3: "CHF/m\xB2",
  ytd_net_return: "%",
  three_months_return: "%",
  r_cost_price_median: "CHF/m\xB2",
  one_month_return: "%",
  r_total_cost_price: "CHF",
  c_market_value_q1: "CHF/m\xB2",
  r_cost_price_q1: "CHF/m\xB2",
  c_market_value_q3: "CHF/m\xB2",
  c_total_market_value: "CHF",
  r_residential_cost_price: "CHF",
  r_commercial_cost_price: "CHF",
  r_mixed_cost_price: "CHF",
  c_residential_market_value: "CHF",
  c_market_value_median: "CHF/m\xB2",
  c_mixed_market_value: "CHF",
  r_market_value_q1: "CHF/m\xB2",
  c_commercial_market_value: "CHF",
  r_market_value_q3: "CHF/m\xB2",
  r_mixed_market_value: "CHF",
  r_commercial_market_value: "CHF",
  r_total_market_value: "CHF",
  r_net_rent_q1: "CHF/m\xB2",
  r_net_rent_median: "CHF/m\xB2",
  r_net_rent_q3: "CHF/m\xB2",
  r_residential_market_value: "CHF",
  r_max_custodian_bank_fees: "%",
  r_max_construction_and_works_fees: "%",
  r_residential_net_rent: "CHF",
  r_max_share_buyback_commissions: "%",
  r_max_share_issue_commissions: "%",
  r_custodian_bank_fees: "%",
  r_ter_mv: "%",
  r_property_management_fees: "%",
  r_ter_nav: "%",
  r_share_issue_commissions: "%",
  r_purchase_and_sale_fees: "%",
  r_share_buyback_commissions: "%",
  r_max_management_fees: "%",
  r_ter_gav: "%",
  c_earnings_per_share: "CHF",
  r_estimated_liquidation_taxes: "CHF",
  c_operating_profit: "CHF",
  r_management_fees: "%",
  c_total_cash_per_share: "CHF",
  c_loan_to_value: "%",
  c_debt_ratio: "%",
  c_current_ratio: "%",
  c_weighted_average_maturity: "yr",
  c_current_liabilities: "CHF",
  c_weighted_average_cost_of_debt: "%",
  c_payout_ratio: "%",
  c_roe: "%",
  c_price_to_book_value: "%",
  c_pe_ratio: "%",
  c_agio_at_year_end: "%",
  c_net_initial_yield: "%",
  c_roa: "%",
  c_roce: "%",
  r_total_dividend: "CHF",
  m_dividend_yield: "%",
  r_dividend_yield: "%",
  r_payout_ratio: "%",
  r_dividend_from_indirect_rental_income: "CHF",
  r_rental_losses: "%",
  r_performance: "%",
  r_roic: "%",
  r_roi: "%",
  r_debt_ratio: "%",
  r_roe: "%",
  r_ebit_margin: "%",
  c_mixed_net_rent: "CHF",
  c_resiential_net_rent: "CHF",
  c_total_net_rent: "CHF",
  c_net_rent_q3: "CHF/m\xB2",
  c_commercial_net_rent: "CHF",
  r_resiential_net_rent: "CHF",
  r_market_value_median: "CHF/m\xB2",
  c_net_rent_q1: "CHF/m\xB2",
  c_net_rent_median: "CHF/m\xB2",
  r_mixed_net_rent: "CHF",
  c_total_agio_at_year_end: "CHF",
  r_total_net_rent: "CHF",
  r_commercial_net_rent: "CHF",
  r_tna_after_distribution: "CHF",
  c_tga_compounded: "CHF",
  r_tna_before_distribution: "CHF",
  r_tga_after_distribution: "CHF",
  c_tna_compounded: "CHF",
  r_tga_before_distribution: "CHF",
  c_total_current_agio_compounded: "CHF",
  r_market_cap: "CHF",
  r_nav_before_distribution: "CHF",
  c_total_current_agio_after_distribution: "CHF",
  r_agio_at_year_end: "%",
  r_share_price: "CHF",
  c_current_agio_compounded: "%",
  c_nav_after_distribution: "CHF",
  r_gav_before_distribution: "CHF",
  c_gav_after_distribution: "CHF",
  c_nav_compounded: "CHF",
  c_current_agio_after_distribution: "%",
  m_share_price: "CHF",
  real_charges_monthly: "CHF",
  potential_charges_monthly: "CHF",
  c_five_years_total_return: "%",
  c_three_years_total_return: "%",
  potential_net_rent_monthly: "CHF",
  beta: "%",
  alpha: "%",
  c_median_cost_of_debt: "%",
  real_net_rent_monthly: "CHF",
  hist_volatility_30_days: "%",
  implied_volatility: "%",
  hist_volatility_90_days: "%",
  potential_net_rent_per_m2: "CHF",
  r2: "%",
  hist_volatility_250_days: "%",
  hist_volatility_180_days: "%",
  potential_charges_per_m2: "CHF",
  tx_price: "CHF",
  potential_roa: "%",
  estimated_area: "m\xB2",
  solar_existing: "kW",
  duplex: "m\xB2",
  real_market_value: "CHF",
  potential_charges: "CHF",
  potential_market_value_per_m2: "CHF",
  real_roa: "CHF",
  potential_market_value: "CHF",
  r_capital_employed: "CHF",
  ground_area: "m\xB2",
  potential_total_income: "CHF",
  r_total_area: "m\xB2",
  r_residential_area: "m\xB2",
  commercial_spaces_area: "m\xB2",
  one_year_total_return: "%",
  m_share_price_var: "%",
  r_commercial_spaces_area: "m\xB2",
  open: "CHF",
  three_years_total_return: "%",
  five_years_total_return: "%",
  last: "CHF",
  m_market_cap: "CHF",
  low: "CHF",
  high: "CHF",
  r_wault: "yr",
  c_gav_compounded: "CHF",
  r_roce: "%",
  m_dividend: "CHF",
  r_other_revenue: "CHF",
  c_revenue_per_share: "CHF",
  r_other_short_term_liabilities: "CHF",
  r_roa: "%",
  r_other_interest: "CHF",
  r_other_expenses: "CHF",
  r_ordinary_income_carried_forward_from_previous_financial_year: "CHF",
  r_net_income: "CHF",
  r_other_long_term_liabilities: "CHF",
  r_net_assets_at_end_of_the_financial_year: "CHF",
  r_other_assets: "CHF",
  r_nopat: "CHF",
  r_net_income_available_for_distribution: "CHF",
  r_net_assets_at_beginning_of_the_financial_year: "CHF",
  r_net_assets_before_estimated_liquidation_taxes: "CHF",
  r_negative_interest: "CHF",
  r_long_term_liabilities: "CHF",
  r_ebit: "CHF",
  r_invested_capital: "CHF",
  r_long_term_interest_bearing_mortgages: "CHF",
  r_dividend_distribution: "CHF",
  r_mortgage_interest: "CHF",
  r_dividend_from_direct_rental_income: "CHF",
  c_median_maturity: "yr",
  c_weighted_average_interest_rate: "%",
  r_dividend_from_capital_gains: "CHF",
  c_ebit_margin: "%",
  r_max_purchase_and_sale_fees: "%",
  r_auditing_expenses: "CHF",
  r_max_property_management_fees: "%",
  r_valuation_expenses: "CHF",
  r_administration_of_buildings: "CHF",
  r_market_maker_fees: "CHF",
  r_total_net_assets: "CHF",
  r_absolute_custodian_bank_fees: "CHF",
  r_absolute_management_fees: "CHF",
  r_valuation_and_auditing_expenses: "CHF",
  r_unrealised_capital_gains_and_losses: "CHF",
  r_total_revenue: "CHF",
  r_total_properties: "CHF",
  r_total_liabilities: "CHF",
  r_total_interest: "CHF",
  r_total_expenses: "CHF",
  r_taxes: "CHF",
  r_total_cash: "CHF",
  r_total_income: "CHF",
  r_short_term_liabilities: "CHF",
  r_retained_earnings: "CHF",
  r_short_term_interest_bearing_mortgages: "CHF",
  r_rental_income: "CHF",
  r_realised_income: "CHF",
  r_revenue_from_postal_and_bank_accounts: "CHF",
  r_property_management_expenses: "CHF",
  r_property_tax: "CHF",
  r_realised_capital_gains_and_losses: "CHF",
  r_profit_and_capital_taxes: "CHF",
  r_maintenance_and_repairs_expenses: "CHF",
  r_maintenance_costs: "CHF",
  r_capital_distribution: "CHF",
  r_building_expenses: "CHF",
  r_construction_and_works_fees: "%",
  rental_losses: "%",
  energy: {
    distribution_area: "m\xB2"
  },
  net_initial_yield: "%",
  gross_rent: "CHF",
  description: {
    distribution_area: "m\xB2",
    distribution: "%"
  },
  rate: "%",
  market_value_per_m2: "CHF/m\xB2",
  portfolios: {
    distribution_area: "m\xB2",
    hail_hazard_10_years_average: "%",
    accessibility: "m",
    overbank_sedimentation: "%",
    avalanches: "%",
    debris_flow: "%",
    rockfalls: "%",
    floods_50_years: "%",
    distribution: "%",
    landslides: "%"
  },
  wault: "jr",
  share_class_nb: "Einheiten",
  roic: "%",
  roi: "%",
  household_size: "ppl.",
  false: "Nein",
  roe: "%",
  true: "Ja",
  immigration: "%",
  inhabitants_per_100_m2: "ppl.",
  emigration: "%",
  inhabitants_per_room: "ppl.",
  accessibility_index: "%",
  actual_rental_status: "CHF",
  income_per_taxpayer: "CHF",
  cost_price: "CHF",
  charges_per_m2: "CHF/m\xB2",
  rent_per_m2: "CHF/m\xB2",
  price_per_m2: "CHF/m\xB2",
  total_tenant_charges: "CHF",
  residential_net_rent_m2: "CHF/m\xB2",
  commercial_net_rent_m2: "CHF/m\xB2",
  funds: {
    transactions: {
      value: "CHF"
    },
    overbank_sedimentation: "%",
    hail_hazard_10_years: "%",
    sia261: "%",
    rockfalls: "%",
    debris_flow: "%",
    landslides: "%",
    avalanches: "%",
    floods_50_years: "%"
  },
  roa: "%",
  energy_intensity: "kWh/m\xB2/Jahr",
  ter_mv: "%",
  ter_nav: "%",
  climate_risk_index: "%",
  market_price_var: "%",
  market_price: "CHF",
  five_years_avg_dividend_yield: "%",
  return_on_asset: "%",
  total_expense_ratio_ref_nav: "%",
  return_on_capital_employed: "%",
  dividend_yield: "%",
  dividend: "CHF",
  potential_net_rent: "CHF",
  built_up: {
    area: "m\xB2",
    ratio: "%"
  },
  real_charges: "CHF",
  demographic: {
    ages: "%"
  },
  real_net_rent: "CHF",
  yearly_management_fee: "%",
  ebit_margin: "%",
  rent_default_rate: "%",
  total_expense_ratio_ref_mv: "%",
  total_expense_ratio_ref_gav: "%",
  debt_financing_ratio: "%",
  return_on_equity: "%",
  return_on_invested_capital: "%",
  distribution_yield: "%",
  return_on_investment: "%",
  relative_agio: "%",
  distribution_value: "CHF",
  market_capitalization: "CHF",
  payout_ratio: "%",
  total_gross_assets: "CHF",
  share_class_nav: "CHF",
  share_class_gav: "CHF",
  swiss_net_rent: "CHF",
  canton_rent: "CHF",
  share_market_price: "CHF",
  press_score: "/10",
  birth: "%",
  death: "%",
  marriage: "%",
  volume: "m\xB3",
  divorce: "%",
  lot_area: "m\xB2",
  area: "m\xB2",
  transport_bus: "m",
  surface: "m\xB2",
  transport_train: "m",
  healthcare: "m",
  secondary_education: "m",
  shops: "m",
  restaurants: "m",
  primary_education: "m",
  early_years: "m",
  hail_hazard_10_years_average: "cm",
  climate_normals_precipitation_1991_2020: "mm/Jahr",
  aged_care: "m",
  cultural_venues: "m",
  climate_normals_sunshine_duration_1991_2020: "%",
  market_cap: "CHF",
  ter_gav: "%",
  market_value: "CHF",
  total_co2: "kg/m\xB2/Jahr",
  charges: "CHF",
  total_net_assets: "CHF",
  climate_normals_temperature_1991_2020: "\xB0C",
  decibels: "dB",
  total_area: "m\xB2",
  industrial_area: "m\xB2",
  agio: "%",
  debt_ratio: "%",
  net_rent: "CHF",
  office_area: "m\xB2",
  commercial_area: "m\xB2",
  idc_m2: "kWh/m\xB2/Jahr",
  co2_m2: "kg/m\xB2/Jahr",
  solar_potential: "kWh/Jahr",
  co2: "kg/Jahr",
  residential_area: "m\xB2",
  idc: "kWh/Jahr",
  solar_existing_per_m2: "kWh/m\xB2"
};
var table_header = {
  building_category: "Geb\xE4udekategorie",
  r_unrealised_gains_and_losses_including_var_est_liq_tax: "Nicht realisierte Gewinne/Verluste einschlie\xDFlich Liquidationssteueranpassung",
  sector_nb: "Sektor Nummer",
  lot_nb: "Parzellennummer",
  region: "Region",
  r_gross_yield: "Bruttorendite",
  r_cost_price: "Einstandspreis",
  r_rental_space: "Vermietungsfl\xE4che",
  r_target_rent: "Zielmieteinkommen",
  r_real_rent: "Mieteinnahmen",
  r_market_value: "Marktwert",
  potential_total_income: "Netto-Miete",
  r_capital_employed: "Kapital eingesetzt",
  net_rent: "Netto-Miete",
  vwap: "VWAP",
  lots_nb: "Anzahl der Grundst\xFCcke",
  commercial_spaces_area: "Gewerbegebiet",
  residential_area: "Wohngebiet",
  dwellings_nb: "Anzahl der Wohnungen",
  building_groups_nb: "Anzahl der Geb\xE4udeverb\xE4nde",
  buildings_nb: "Anzahl der Geb\xE4ude",
  r_total_area: "Gesamtfl\xE4che",
  r_commercial_spaces_area: "Gewerbegebiet",
  total_area: "Gesamtfl\xE4che",
  m_share_price_var: "Aktienkursvariation",
  r_residential_area: "Wohngebiet",
  ytd_net_return: "JTD-Nettoertrag",
  volume: "Volumen",
  three_years_total_return: "3Y Gesamtrendite",
  one_year_total_return: "1J Gesamtertrag",
  five_years_total_return: "5J Gesamtbetrag",
  six_months_return: "6M Nettorendite",
  turnover: "Umsatz",
  three_months_avg_volume: "3M Durchschnittsvolumen",
  three_years_return: "3J Nettorendite",
  m_unit_outstanding: "Hervorragende Einheiten",
  three_months_return: "3M Nettorendite",
  qtd_net_return: "QTD Nettorendite",
  one_year_return: "1J Nettorendite",
  six_months_avg_volume: "6M durchschnittliches Volumen",
  open: "\xD6ffnen",
  one_year_avg_volume: "1J durchschnittliches Volumen",
  r2: "R2",
  mtd_net_return: "MTD Nettorendite",
  last: "Letzte",
  implied_volatility: "Implizite Volatilit\xE4t",
  m_market_cap: "Marktkapitalisierung",
  one_month_avg_volume: "1M durchschnittliches Volumen",
  low: "Niedrig",
  one_month_return: "1M Nettorendite",
  hist_volatility_90_days: "3M historische Volatilit\xE4t",
  c_three_years_total_return: "3J Gesamtertrag",
  hist_volatility_180_days: "6M historische Volatilit\xE4t",
  five_years_return: "5Y Nettorendite",
  hist_volatility_30_days: "1M historische Volatilit\xE4t",
  hist_volatility_250_days: "1J historische Volatilit\xE4t",
  high: "Hoch",
  c_five_years_total_return: "5J Gesamtertrag",
  five_years_avg_dividend_yield: "5J durchschnittliche Dividendenrendite",
  five_days_avg_volume: "5D Durchschnittsvolumen",
  fifty_two_weeks_high: "52W hoch",
  fifty_two_weeks_low: "52W niedrig",
  m_dividend_yield: "Aktuelle Dividendenrendite",
  beta: "Beta",
  c_total_current_agio_compounded: "Gesamtes aktuelles Agio kumuliert",
  m_share_price: "Aktienkurs",
  alpha: "Alpha",
  c_tna_compounded: "TNA kombiniert",
  c_total_current_agio_after_distribution: "Gesamtaktuelles Agio nach Verteilung",
  c_nav_compounded: "NAV zusammengesetzt",
  c_tga_compounded: "TGA gemischt",
  c_gav_compounded: "GAV kumuliert",
  r_tna_before_distribution: "TNA vor der Verteilung",
  c_current_agio_compounded: "Aktuelles Agio kumuliert",
  c_total_agio_at_year_end: "Gesamtes Agio zum Jahresende",
  c_current_agio_after_distribution: "Aktuelles Agio nach Verteilung",
  r_tna_after_distribution: "TNA nach der Verteilung",
  r_market_cap: "Marktkapitalisierung zum Jahresende",
  r_tga_after_distribution: "TGA nach der Verteilung",
  r_tga_before_distribution: "TGA vor der Verteilung",
  r_nav_before_distribution: "NAV vor der Verteilung",
  last_report_date: "Letzter Berichtsdatum",
  r_gav_before_distribution: "GAV vor der Verteilung",
  r_agio_at_year_end: "Agio zum Jahresende",
  c_nav_after_distribution: "NAV nach der Verteilung",
  c_agio_at_year_end: "Agio zum Jahresende",
  c_gav_after_distribution: "GAV nach Verteilung",
  launch_date: "Startdatum",
  legal_form: "Rechtsform",
  type: "Art",
  bloomberg_ticker: "Ticker",
  r_units_outstanding: "Ausstehende Einheiten",
  m_dividend_date: "Dividenden-Datum",
  name: "Name",
  m_dividend: "Dividende",
  short_name: "Kurzer Name",
  isin: "ISIN",
  c_weighted_average_maturity: "Gewichtete Durchschnittslaufzeit",
  r_roe: "ROE",
  r_roa: "ROA",
  c_weighted_average_cost_of_debt: "Gewichteter durchschnittlicher Fremdkapitalkosten",
  r_roic: "ROIC",
  r_wault: "WAULT",
  r_roi: "ROI",
  r_roce: "ROCE",
  r_other_interest: "Andere Interessen",
  r_rental_losses: "Vermietungsverluste",
  r_payout_ratio: "Auszahlungsverh\xE4ltnis",
  r_other_short_term_liabilities: "Andere kurzfristige Verbindlichkeiten",
  r_other_long_term_liabilities: "Sonstige langfristige Verbindlichkeiten",
  r_other_revenue: "Sonstige Einnahmen",
  c_revenue_per_share: "Umsatz pro Aktie",
  r_performance: "Leistung",
  r_other_assets: "Andere Verm\xF6genswerte",
  r_net_income_available_for_distribution: "Nettoergebnis verf\xFCgbar f\xFCr die Verteilung",
  r_nopat: "NOPAT",
  r_net_income: "Nettoeinkommen",
  r_ordinary_income_carried_forward_from_previous_financial_year: "Ordentliches Einkommen, das aus dem vorherigen Gesch\xE4ftsjahr vorgetragen wird",
  r_net_assets_before_estimated_liquidation_taxes: "Nettoverm\xF6gen vor gesch\xE4tzten Liquidationssteuern",
  r_net_assets_at_end_of_the_financial_year: "Nettoverm\xF6gen am Ende des Gesch\xE4ftsjahres",
  r_other_expenses: "Andere Ausgaben",
  c_median_maturity: "Medianreife",
  r_long_term_liabilities: "Langfristige Verbindlichkeiten",
  r_negative_interest: "Negative Zinsen",
  c_median_cost_of_debt: "Median-Kosten der Verschuldung",
  r_net_assets_at_beginning_of_the_financial_year: "Nettoverm\xF6gen zu Beginn des Gesch\xE4ftsjahres",
  r_mortgage_interest: "Hypothekenzins",
  c_earnings_per_share: "Gewinn pro Aktie",
  r_dividend_from_direct_rental_income: "Dividende aus direkten Mieteinnahmen",
  r_ebit_margin: "EBIT-Marge",
  r_ebit: "EBIT",
  r_dividend_yield: "Dividendenrendite zum Jahresende",
  r_long_term_interest_bearing_mortgages: "Langfristige Hypotheken mit Zinsen",
  r_dividend_from_indirect_rental_income: "Dividende aus indirekten Mieteinnahmen",
  r_invested_capital: "Investiertes Kapital",
  c_total_cash_per_share: "Gesamtes Bargeld pro Aktie",
  r_debt_ratio: "Verschuldungsquote",
  c_roe: "ROE",
  r_dividend_from_capital_gains: "Dividende aus Kapitalgewinnen",
  r_dividend_distribution: "Dividendenverteilung",
  c_operating_profit: "Betriebsgewinn",
  c_roce: "ROCE",
  c_roa: "ROA",
  c_payout_ratio: "Auszahlungsverh\xE4ltnis",
  c_price_to_book_value: "Preis-Buchwert-Verh\xE4ltnis",
  c_pe_ratio: "P/E-Verh\xE4ltnis",
  r_ter_nav: "TERrefNAV",
  c_current_liabilities: "Aktuelle Verbindlichkeiten",
  c_debt_ratio: "Verschuldungsquote",
  c_current_ratio: "Aktuelles Verh\xE4ltnis",
  c_loan_to_value: "Darlehen zu Wert",
  c_ebit_margin: "EBIT-Marge",
  c_net_initial_yield: "Nettoanfangsrendite",
  r_ter_mv: "TERrefMV",
  r_max_share_buyback_commissions: "Maximale Aktienr\xFCckkaufprovisionen",
  r_share_buyback_commissions: "Aktienr\xFCckkaufkommissionen",
  r_max_share_issue_commissions: "Maximaler Anteil der Emissionsprovisionen",
  r_share_price: "Aktienkurs",
  r_max_purchase_and_sale_fees: "Maximaler Kauf- und Verkaufsgeb\xFChren",
  r_share_issue_commissions: "Aktienemissionsprovisionen",
  r_ter_gav: "TERrefGAV",
  r_max_management_fees: "Maximale Verwaltungsgeb\xFChren",
  r_max_property_management_fees: "Maximale Verwaltungsgeb\xFChren f\xFCr Immobilien",
  r_market_maker_fees: "Marktmachergeb\xFChren",
  r_max_construction_and_works_fees: "Maximale Bau- und Arbeitsgeb\xFChren",
  r_max_custodian_bank_fees: "Max Verwahrstellengeb\xFChren",
  r_absolute_management_fees: "Managementgeb\xFChren",
  r_administration_of_buildings: "Verwaltung von Geb\xE4uden",
  r_custodian_bank_fees: "Verwahrstellengeb\xFChren",
  r_management_fees: "Managementgeb\xFChren",
  r_auditing_expenses: "Aufwendungen pr\xFCfen",
  r_absolute_custodian_bank_fees: "Verwahrstellengeb\xFChren",
  energy_intensity: "Energieintensit\xE4t",
  total_co2: "CO\u2082-Emissionen",
  governance_rating: "G Bewertung",
  social_rating: "S-Bewertung",
  esg_rating: "ESG-Bewertung",
  environmental: "E Punktzahl",
  governance: "G Score",
  social: "S Punktzahl",
  environmental_rating: "E Bewertung",
  r_valuation_expenses: "Bewertungskosten",
  r_valuation_and_auditing_expenses: "Bewertungs- und Pr\xFCfungsaufwendungen",
  r_unrealised_capital_gains_and_losses: "Nicht realisierte Kapitalgewinne und -verluste",
  r_total_revenue: "Gesamteinnahmen",
  esg: "ESG-Punktzahl",
  r_total_properties: "Gesamtimmobilien",
  r_total_income: "Gesamteinkommen",
  r_total_liabilities: "Gesamtverbindlichkeiten",
  r_total_net_assets: "Gesamtes Nettoverm\xF6gen",
  r_total_interest: "Totalzinsen",
  r_short_term_liabilities: "Kurzfristige Verbindlichkeiten",
  r_taxes: "Steuern",
  r_total_expenses: "Gesamtausgaben",
  r_total_cash: "Gesamtes Bargeld",
  r_total_dividend: "Gesamtdividende",
  r_realised_capital_gains_and_losses: "Realisierten Kapitalgewinne und -verluste",
  r_short_term_interest_bearing_mortgages: "Kurzfristige Hypotheken mit Zinsen",
  r_revenue_from_postal_and_bank_accounts: "Umsatz aus Post- und Bankkonten",
  r_realised_income: "Eingehaltetes Einkommen",
  r_rental_income: "Mieteinnahmen",
  r_retained_earnings: "Gewinnr\xFCcklagen",
  r_property_tax: "Grundsteuer",
  r_property_management_expenses: "Immobilienverwaltungsaufwendungen",
  r_profit_and_capital_taxes: "Gewinn- und Kapitalsteuern",
  r_purchase_and_sale_fees: "Kauf- und Verkaufsgeb\xFChr",
  r_property_management_fees: "Verwaltungsgeb\xFChren f\xFCr Immobilien",
  r_estimated_liquidation_taxes: "Gesch\xE4tzte Liquidationssteuern",
  r_maintenance_and_repairs_expenses: "Wartungs- und Reparaturkosten",
  r_construction_and_works_fees: "Bau- und Arbeitsgeb\xFChren",
  r_maintenance_costs: "Instandhaltungskosten",
  r_capital_distribution: "Kapitalverteilung",
  r_building_expenses: "Geb\xE4udekosten",
  canton: "Kanton",
  real_market_value: "Marktwert (notiert)",
  estimated_area: "Bereich",
  zip: "Zip",
  value: "Wert",
  real_roa: "ROA",
  egrid: "EGRID",
  has_building: "Hat Geb\xE4ude",
  actual_target_roa: "Ziel ROA",
  floor: "Fu\xDFboden",
  real_net_rent_monthly: "Monatliches Mieteinkommen",
  actual_market_value: "Marktwert ",
  address: "Adresse",
  municipality_name: "Gemeinde",
  debt_ratio: "Verschuldungsquote",
  roa: "ROA",
  total_net_assets: "Gesamtnettoverm\xF6gen",
  actual_rental_status_target: "Zielmieteinnahmen (berichtet)",
  actual_rental_status_real: "Mieteinnahmen",
  ter_gav: "TERrefGAV",
  ewid: "Ewid",
  street_short: "Adresse",
  cost_price: "Kostenpreis",
  floors_nb: "Anzahl der Stockwerke",
  rooms_nb: "Zimmeranzahl",
  locality: "Ortslage",
  counterpart_fund: "Gegenseitiger Fonds",
  label: "Label",
  construction_date: "Baujahr",
  id: "ID",
  rent_defaults: "Mietausf\xE4lle",
  agio: "Agio",
  actual_real_roa: "ROA",
  last_renovation_year: "Letztes Renovierungsjahr",
  commercial_area: "Gewerbegebiet",
  market_cap: "Marktkapitalisierung",
  duplex: "Duplex",
  date: "Jahr",
  real_charges_monthly: "Monatliche Mietergeb\xFChren",
  egid: "EGID",
  rights: "Rechte",
  role: "Rolle",
  actions: "Mehr Aktionen",
  area: "Bereich",
  fund_name: "Fondsname",
  market_price: "Aktienkurs",
  state: "Einladungsprozess",
  last_activity_date: "Zuletzt aktiv",
  companies: "Unternehmen",
  subscribed_date: "Seit abonniert",
  last_active: "Zuletzt aktiv",
  value_net_asset: "Nettoverm\xF6gen",
  value_gross_asset: "Bruttowert der Verm\xF6genswerte",
  share_class_nb: "Aktien nb",
  environment: {
    score: "Umweltbewertung"
  },
  total_gross_assets: "Gesamtbruttoverm\xF6gen",
  co2: "CO\u2082",
  ter_nav: "TERrefNAV",
  dividend_date: "Dividendentermin",
  dividend: "Dividende",
  payout_ratio: "Auszahlungsverh\xE4ltnis",
  ter_mv: "TERrefMV",
  roe: "ROE",
  share_class_gav: "Bruttowert der Verm\xF6genswerte",
  yearly_management_fee: "Managementgeb\xFChr",
  ticker: "Ticker",
  rent_default_rate: "Mietausfall",
  roic: "ROIC",
  roi: "ROI",
  share_class_nav: "Nettoverm\xF6genswert",
  rate_type: "Bewertungsart",
  start: "Start",
  real_net_rent: "Mieteinnahmen (aufgef\xFChrt)",
  rate: "Bewertung",
  currency: "W\xE4hrung",
  dividend_yield: "Dividendenrendite",
  end: "Ende",
  already_reimbursed: "Bereits erstattet",
  estimated_co2_emission_per_m2: "CO\u2082 pro m\xB2",
  fund_names: "Vermietername",
  real_charges: "Mietergeb\xFChren (aufgelistet)",
  estimated_idc_per_m2: "Energierelevanz pro m\xB2",
  enterprise: "Unternehmen",
  minergie_label: "Label",
  expires_at: "Ablaufdatum",
  created_at: "Erstellungsdatum",
  developer_mode: "Entwicklermodus",
  user: "User",
  energy_source_heating: "Heizquelle",
  net_initial_yield: "Nettoanfangsrendite",
  fund_legal_forms: "Art des Vermieters",
  solar_existing: "Installierte Solarenergie",
  energy_source_water: "Wasserheizquelle",
  labels: "Minergie Labels",
  ground_area: "Grundfl\xE4che",
  rental_losses: "Vermietungsverluste",
  buyer_name: "K\xE4ufer",
  seller_name: "Verk\xE4ufer",
  municipality_nb: "Gemeinde Nr",
  potential_charges_monthly: "Monatliche Mietkosten",
  potential_net_rent: "Mieteinnahmen (gesch\xE4tzt)",
  potential_roa: "ROA",
  potential_market_value: "Marktwert",
  potential_net_rent_monthly: "Monatliche Mieteinnahmen",
  potential_gross_rent: "Bruttomiete",
  potential_net_rent_per_m2: "Mieteinnahmen pro m\xB2",
  potential_charges: "Mietergeb\xFChren (gesch\xE4tzt)",
  potential_charges_per_m2: "Geb\xFChren pro m\xB2",
  potential_market_value_per_m2: "Marktwert pro m\xB2",
  potential_gross_rent_per_m2: "Bruttomiete pro m\xB2"
};
var global = {
  chart: {
    legend: {
      none: "Keine Heizung",
      m_market_cap: "Marktkapitalisierung",
      m_share_price: "Aktienpreis",
      co_2_scope_1_2_33_per_m_2: "CO\u2082 Bereich 1 + 2 + 3.3",
      total_co_2_scope_1_2_33: "Gesamt CO\u2082 Umfang 1 + 2 + 3.3",
      total_co_2_scope_1_2: "Gesamt CO\u2082 Bereich 1 + 2",
      total_idc: "Gesamtenergie",
      idc_per_m_2: "Energiintensit\xE4t",
      co_2_scope_1_per_m_2: "CO\u2082 Bereich 1",
      co_2_scope_2_per_m_2: "CO\u2082 Bereich 2",
      co_2_scope_33_per_m_2: "CO\u2082 Bereich 3.3",
      co_2_scope_1_2_per_m_2: "CO\u2082 Bereich 1 + 2",
      total_co_2_scope_33: "Total CO\u2082 Umfang 3.3",
      total_co_2_scope_2: "Total CO\u2082 Scope 2",
      total_co_2_scope_1: "Gesamt CO\u2082 Bereich 1",
      r_cost_price: "Kostenpreis von Geb\xE4uden",
      r_share_price: "Aktienkurs",
      r_target_rent: "Zielmieteinkommen",
      r_tna_before_distribution: "TNA vor der Verteilung",
      r_tga_before_distribution: "TGA vor der Verteilung",
      r_market_cap: "Marktkapitalisierung",
      r_debt_ratio: "Verschuldungsquote",
      r_ebit_margin: "Ebit-Marge",
      r_rental_losses: "Vermietungsverluste",
      r_roe: "ROE",
      r_dividend_yield: "Dividendenrendite",
      r_payout_ratio: "Auszahlungsquote",
      r_nav_before_distribution: "NAV vor Verteilung",
      r_market_value: "Marktwert von Geb\xE4uden",
      c_market_value: "Marktwert von Geb\xE4uden",
      c_net_rent: "Mieteinnahmen",
      r_real_rent: "Reale Miete",
      liabilities: "Verbindlichkeiten",
      equity: "Eigenkapital",
      asset: "Verm\xF6genswert",
      default: "Betrag",
      roa: "ROA",
      surface: "Oberfl\xE4che",
      co_2_expected: "CO\u2082 (erwartet)",
      co_2_listed: "CO\u2082",
      gav: "GAV",
      nav: "NAV",
      rent_expected: "Erwartete Mieteinnahmen",
      rent_listed: "Mieteinnahmen",
      realised_gains_losses: "Realisierte Gewinne/Verluste",
      realised_income: "Einkommen realisiert",
      net_income_available_for_distribution: "Nettoeinkommen verf\xFCgbar f\xFCr die Verteilung",
      ordinary_income_carried_forward: "Ordentliches Einkommen vorgetragen",
      total_income: "Gesamteinkommen",
      unrealised_gains_losses_including_liquidation_taxes_variation: "Unrealisierte Gewinne/Verluste einschlie\xDFlich der Variation der Liquidationsteuern",
      net_income: "Nettoeinkommen",
      expenses: "Ausgaben",
      revenues: "Ums\xE4tze",
      total_net_asset: "Gesamt netto Verm\xF6gen",
      other_long_term_liabilities: "Andere langfristige Verbindlichkeiten",
      long_term_interest_bearing_mortgages: "Langfristige zinsbringende Hypotheken",
      long_term_liabilities: "Langfristige Verbindlichkeiten",
      other_short_term_liabilities: "Andere kurzfristige Verbindlichkeiten",
      short_term_interest_bearing_mortgages: "Kurzfristige zinstragende Hypotheken",
      short_term_liabilities: "Kurzfristige Verbindlichkeiten",
      other_asset: "Andere Verm\xF6genswerte",
      total_properties: "Gesamte Immobilien",
      other: "Andere",
      non_fossil: "Nicht fossil",
      fossil: "Fossil",
      tessin: "Tessin",
      zentralschweiz: "Zentralschweiz",
      espace_mitelland: "Espace Mitelland",
      ostschweiz: "Ostschweiz",
      region_lemanique: "Genfersee-Region",
      zurich: "Z\xFCrich",
      nordwestschweiz: "Nordwestschweiz",
      cash_holding_and_balances_at_sight: "Barbest\xE4nde und Guthaben auf Sicht",
      woman: "Frau",
      man: "Man",
      legend: "Legende",
      amount: "Betrag",
      date: "Datum"
    },
    tooltip: {
      potential: "Erwartet",
      real: "Aufgelistet"
    }
  },
  assets: {
    lot: "Los",
    dwelling: "Wohnung",
    building: "bauen"
  }
};
var fund = {
  financials: {
    accounting: {
      table_action: {}
    }
  }
};
var license_agreement = {
  input: {},
  update: {},
  part12: {},
  part13: {},
  part11: {},
  part9: {},
  part10: {},
  part8: {},
  part7: {},
  part3: {},
  part4: {},
  part5: {},
  part6: {},
  part1: {},
  part2: {},
  intro: {}
};
var footer = {
  confidential_policy: "Vertrauliche Richtlinie",
  impressum: "Impressum"
};
var table_actions = {
  request_download: {
    csv: "CSV der ausgew\xE4hlten Zeilen auf der aktuellen Seite herunterladen"
  },
  add_to_portfolios: {
    title: "Gew\xE4hlte Verm\xF6genswerte zum Portfolio hinzuf\xFCgen"
  },
  portfolios: {
    remove: {
      confirmation: {
        title: "Entfernen Sie die ausgew\xE4hlten Verm\xF6genswerte aus dem aktuellen Portfolio"
      }
    }
  },
  watchlist: {
    add: {
      button: {
        label: "Ausgew\xE4hlte Verm\xF6genswerte verfolgen"
      }
    },
    remove: {
      button: {
        label: "Entfernen Sie die ausgew\xE4hlten Verm\xF6genswerte aus der Beobachtungsliste"
      }
    }
  }
};
var table = {
  fund_type: {
    commercial: "Kommerziell",
    mixed: "Gemischt",
    residential: "Wohn-"
  },
  error: "Ein Fehler ist aufgetreten \u{1F647}",
  fund_legal_form: {
    unlisted_fund: "Unlisted fund",
    foundation: "Stiftung",
    real_estate_firm: "Immobilienfirma",
    listed_fund: "Aufgelisteter Fonds"
  },
  empty: "Keine Daten (noch) wir arbeiten daran \u{1F3C3}\u200D\u2642\uFE0F"
};
var boarding_page = {
  totp_setup: {
    notification: {
      privileged_session_reached: {
        message: "Bevorzugte Sitzung abgelaufen. Bitte melden Sie sich erneut an."
      }
    }
  },
  register: {
    notification: {
      privileged_session_reached: {
        message: "Aus Sicherheitsgr\xFCnden muss der Registrierungsprozess innerhalb von 30 Minuten abgeschlossen sein. Bitte fordern Sie einen weiteren Einladungslink an, um die Registrierung neu zu starten.",
        title: "Privilegierte Sitzung Zeit\xFCberschreitung"
      },
      changed_email: {
        message: "Ihre Informationen wurden erfolgreich aktualisiert - Sie sollten eine neue E-Mail erhalten haben, um Ihre neue E-Mail-Adresse zu verifizieren.",
        title: "E-Mail-Adresse ge\xE4ndert"
      },
      flow_expiration: {
        message: "Der Flow ist abgelaufen - Kontaktieren Sie Ihren Administrator.",
        title: "Abgelaufen"
      }
    },
    repeat_password: "Passwort wiederholen",
    error: {
      repeat_password: "Passw\xF6rter m\xFCssen identisch sein"
    },
    button: "Speichern",
    subtitle: "Richten Sie Ihr Konto ein:",
    title: "Willkommen bei Quanthome_"
  },
  login: {
    missing_otp: {
      description: "Das Einmalpasswort (OTP) f\xFCr dieses Konto ist nicht konfiguriert oder ist falsch. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an Ihren Administrator, um das Problem zu beheben.",
      title: "Ung\xFCltige OTP-Einrichtung"
    },
    aal2: {
      submit_button: "Einreichen",
      forgot_button: "Verwenden Sie Wiederherstellungscodes",
      subtitle: "Bitte schlie\xDFen Sie die zweite Authentifizierungsherausforderung ab.",
      title: "Geben Sie die OTP (Einmalpasswort) ein"
    },
    aal1: {
      submit_button: "Einloggen",
      forgot_button: "Passwort vergessen/zur\xFCcksetzen?",
      subtitle: "Willkommen zur\xFCck!",
      title: "Loggen Sie sich in Ihr Konto ein"
    },
    failed: {
      title: "Fehler beim Protokollieren"
    },
    abort: "Stornieren"
  },
  recovery_code: {
    missing: {
      title: "Ung\xFCltiger Wiederherstellungscode",
      description: "Der Wiederherstellungscode f\xFCr dieses Konto ist nicht definiert oder ist abgelaufen. Bitte kontaktieren Sie den Support von Quanthome, um das Problem zu l\xF6sen."
    },
    abort_button: "Stornieren",
    faq: {
      lock: {
        answer: "Bitte wenden Sie sich an Ihren Administrator zur Wiederherstellung.",
        question: "Ausgeschlossen?"
      },
      what_is_recovery: {
        answer: "Der Wiederherstellungscode ist der Code, den Sie erhalten haben, als Sie Ihr Ger\xE4t zum ersten Mal verbunden haben. Sie sollten ihn an einem sicheren Ort aufbewahrt haben.",
        question: "Was ist ein Wiederherstellungscode?"
      },
      description: "Wenn Sie auf Ihr mobiles Ger\xE4t nicht zugreifen k\xF6nnen, geben Sie Ihren Wiederherstellungscode ein, um Ihre Identit\xE4t zu \xFCberpr\xFCfen."
    },
    form: {
      button: "\xDCberpr\xFCfen"
    },
    title: "Zwei-Faktor-Wiederherstellung",
    reset: {
      success: {
        message: "Sie k\xF6nnen jetzt ein neues Ger\xE4t binden.",
        title: "Der Wiederherstellungscode ist korrekt"
      }
    }
  },
  show_recovery: {
    explain: {
      regenerate_code: "Wenn Sie neue Wiederherstellungscodes generieren, m\xFCssen Sie die neuen Codes herunterladen oder ausdrucken. Ihre alten Codes funktionieren nicht mehr."
    },
    label: {
      regenerate_code: "Neue Wiederherstellungscodes generieren"
    },
    button: {
      regenerate_code: "Neue Wiederherstellungscodes generieren",
      proceed: "Fortfahren",
      print: "Print",
      copied: "Kopiert",
      copy: "Kopieren",
      download: "Herunterladen"
    },
    warning: "Bewahren Sie Ihren Wiederherstellungscode an einem sicheren Ort auf. Diese Codes sind das letzte Mittel, um auf Ihr Konto zuzugreifen, falls Sie Ihren zweiten Faktor verlieren. Wenn Sie diesen Code nicht finden k\xF6nnen, <0>verlieren Sie</0> den Zugang zum Konto.",
    subtitle: "Verliere niemals deinen Wiederherstellungscode.",
    title: "Wiederherstellungscode"
  },
  verify_mail: {
    abort: "Werde ich sp\xE4ter machen",
    step: {
      sent_email: {
        button: "\xDCberpr\xFCfungscode",
        success: {
          message: "Ihre E-Mail ist jetzt verifiziert",
          title: "\xDCberpr\xFCfung abgeschlossen"
        },
        description: "Schreiben Sie den Code, den Sie gerade per E-Mail erhalten haben",
        subtitle: "Best\xE4tigen Sie Ihre E-Mail-Adresse"
      },
      choose_method: {
        button: "Code senden",
        success: {
          message: "Eine E-Mail zur Beendigung Ihrer Verifizierung wurde Ihnen gesendet",
          title: "Code gesendet"
        },
        description: "Geben Sie Ihre E-Mail-Adresse ein, um sie zu verifizieren",
        subtitle: "Best\xE4tigen Sie Ihre E-Mail-Adresse"
      }
    },
    title: "Vervollst\xE4ndigen Sie Ihre Einrichtung"
  },
  otp: {
    failed: {
      title: "Fehler beim Einrichten des OTP-Ger\xE4ts"
    },
    button: {
      abort: "Werde ich sp\xE4ter machen",
      verify: "\xDCberpr\xFCfen Sie mein Ger\xE4t mit TOPT"
    },
    step: {
      type_code: "Wenn OTP bereit ist, \xFCberpr\xFCfen Sie es, indem Sie Ihr TOTP eingeben",
      scan_qr: "Scannen Sie diesen QR-Code, um Ihr vertrauensw\xFCrdiges Ger\xE4t \xFCber Ihre OTP-App einzurichten",
      trusted_device: "\xD6ffnen Sie Ihre bevorzugte OTP-App auf Ihrem vertrauensw\xFCrdigen Ger\xE4t"
    },
    legend: "(T)OTP*: (Zeitbasiertes) Einmalpasswort",
    subtitle: "Dual-Faktor-Authentifizierer zum besten Schutz von Daten.",
    title: "Ihr OTP einrichten"
  },
  forgot_password: {
    success: {
      message: "Sie k\xF6nnen sich jetzt mit Ihrem neuen Passwort anmelden.",
      title: "Passwort erfolgreich ge\xE4ndert"
    },
    button: {
      cancel: "Wiederherstellung abbrechen",
      verify: "\xDCberpr\xFCfen Sie meinen Code",
      send: "Schick mir meinen Code",
      save: "Mein Passwort speichern"
    },
    last_step: "Sie k\xF6nnen jetzt Ihr Passwort gegen ein neues \xE4ndern:",
    subtitle: "Keine Panik, alles wird gut...",
    title: "Passwort vergessen"
  }
};
var asset = {
  get_building_from_group: {
    empty: {
      message: "Es gibt kein Geb\xE4ude in dieser Gruppe",
      title: "Kein Geb\xE4ude in dieser Gruppe"
    }
  },
  description: {
    dwellings: {
      rooms_nb: "Zimmernummer",
      ewid: "EWID",
      surface: "Fl\xE4che",
      floor: "Fu\xDFboden",
      construction_date: "Baujahr",
      type: "Typ",
      category: "Kategorie",
      class: "Klasse",
      status: "Status",
      land_use: "Fl\xE4chennutzung",
      city: "Stadt",
      egid: "EGID"
    },
    lots: {
      built_up_ratio: "Be built-up ratio",
      built_up_area: "Bebaute Fl\xE4che",
      municipality: "Gemeinde",
      egrid: "EGRID",
      surface: "Oberfl\xE4che",
      sector: "Sektor",
      number: "Nummer",
      label: "Label",
      type: "Typ",
      land_use: "Fl\xE4chennutzung",
      city: "Stadt"
    },
    amenities: {
      dwellings: {
        imputed_mansarde: "Mansarde",
        imputed_attique: "Attique",
        imputed_balcony: "Balkon",
        imputed_cellar: "Keller"
      },
      buildings: {
        imputed_cachet: "Cachet",
        imputed_near_transports: "Nahe Transporte",
        imputed_near_highway: "In der N\xE4he der Autobahn",
        imputed_bike_room: "Fahrradraum",
        imputed_elevator: "Aufzug",
        fallout_shelter: "Fallout-Unterkunft"
      }
    },
    buildings: {
      dwellings_nb: "Anzahl der Wohnungen",
      floors_nb: "Anzahl der Etagen",
      volume: "Gesamtvolumen",
      area: "Bodenfl\xE4che",
      total_area: "Gesamtfl\xE4che",
      industrial_area: "Industriegebiet",
      commercial_area: "Gewerbegebiet",
      office_area: "B\xFCrobereich",
      residential_area: "Wohngebiet",
      construction_date: "Baujahr",
      type: "Typ",
      category: "Kategorie",
      class: "Klasse",
      status: "Status",
      land_use: "Landnutzung",
      official_nb: "Offizielle Nummer",
      city: "Stadt",
      egid: "EGID"
    }
  }
};
var preset = {
  modal: {
    confirm_delete: {
      cancel: "Zur\xFCck",
      proceed: "Fortfahren",
      text: "Sind Sie sicher, dass Sie diesen Bildschirm l\xF6schen m\xF6chten?",
      title: "L\xF6schen Bildschirm"
    }
  }
};
var tile = {
  wrong: {
    message: "Etwas Falsches passiert"
  },
  empty: {
    message: "Fehlende Daten"
  },
  side_menu: {
    country: "Land",
    canton: "Kanton",
    municipality: "Gemeinde"
  }
};
var error = {
  something_went_wrong: {
    title: "Etwas ist schiefgelaufen"
  },
  email: {
    format: "Ihre E-Mail ist nicht gut geschrieben"
  },
  notfound: {
    button: "Gehe zur\xFCck",
    subtitle: "Entschuldigung, die Seite, die Sie suchen, existiert nicht.",
    title: "Seite nicht gefunden",
    code: "Fehler 404"
  }
};
var companies = {
  button: {
    clear_fund_cache: "Cache des Fonds leeren",
    clear_all_cache: "Alle Cache l\xF6schen",
    clear_cache: "Cache leeren",
    check_states: "\xDCberpr\xFCfen Sie die Staaten",
    give_am_rights: "Gib Am Rechte",
    refresh_roles: "Rollen aktualisieren",
    give_default_roles: "Standardrollen vergeben"
  },
  modal: {
    confirm_delete: {
      text: "Bist du sicher, dass du diese(n) Firma(en) l\xF6schen m\xF6chtest?",
      title: "Best\xE4tigen Sie die L\xF6schung"
    }
  },
  notifications: {
    success_delete_enterprises: "Unternehmen erfolgreich gel\xF6scht",
    no_slot_available: "Keine Slots verf\xFCgbar",
    success_create_users: "Benutzer erfolgreich erstellt"
  }
};
var balance_sheet = {
  label: {
    r_tga_before_distribution: "Gesamtverm\xF6gen",
    r_tna_before_distribution: "TNA vor der Verteilung",
    asset_total: "Gesamtverm\xF6gen",
    r_total_liabilities: "Gesamtschulden",
    r_net_assets_before_estimated_liquidation_taxes: "Nettoverm\xF6gen vor gesch\xE4tzten Liquidationssteuern",
    r_estimated_liquidation_taxes: "Gesch\xE4tzte Liquidationssteuern",
    r_other_long_term_liabilities: "Sonstige langfristige Verbindlichkeiten",
    other_liabilities: "Andere Verbindlichkeiten",
    r_total_net_assets: "Gesamtes Nettoverm\xF6gen",
    r_total_cash: "Gesamtes Bargeld",
    r_total_properties: "Gesamtimmobilien",
    r_other_assets: "Andere Verm\xF6genswerte",
    r_short_term_liabilities: "Kurzfristige Verbindlichkeiten",
    r_short_term_interest_bearing_mortgages: "Kurzfristige zinsbringende Hypotheken",
    r_other_short_term_liabilities: "Andere kurzfristige Verbindlichkeiten",
    r_long_term_liabilities: "Langfristige Verbindlichkeiten",
    r_long_term_interest_bearing_mortgages: "Langfristige zinsbelastete Hypotheken",
    total_liabilities: "Gesamtschulden",
    total_asset: "Gesamtverm\xF6gen",
    date: "Datum"
  },
  unit: {
    chf: "CHF"
  },
  legend: {
    group: {
      liabilities: "Verbindlichkeiten",
      asset: "Verm\xF6genswert",
      equity: "Eigenkapital"
    }
  }
};
var actions = {
  screener: {
    filter_header: {
      header: "Spalten",
      label: "Die Sichtbarkeit der Spalten festlegen"
    },
    per_page: {
      label: "Zeilen pro Seite",
      header: "Anzahl der Zeilen"
    },
    preset: {
      delete: "Bildschirm l\xF6schen",
      copy: "Kopiere Bildschirm",
      reset: "Bildschirm zur\xFCcksetzen",
      save: "Bildschirm speichern"
    },
    filter: {
      search: "Filter",
      edit: "Editieren Sie den Filter",
      save: "Filter speichern",
      remove: "Filter entfernen"
    }
  },
  add_to_watchlist: {
    title: "Zur Beobachtungsliste hinzuf\xFCgen"
  },
  update: {
    processing: {
      done: "Die Operation wurde erfolgreich durchgef\xFChrt. Die Seite wird bald aktualisiert.",
      title: "Aktualisierung",
      message: "Die Operation wird bald durchgef\xFChrt."
    }
  },
  watchlist: {
    remove: {
      button: {
        label: "Entfolgen"
      },
      success: {
        message: "Verm\xF6genswert von Ihrer Beobachtungsliste entfernt"
      }
    },
    add: {
      button: {
        label: "Folgen"
      },
      success: {
        message: "Asset zu Ihrer Beobachtungsliste hinzugef\xFCgt"
      }
    }
  },
  companies: {
    create: "Firma erstellen",
    delete: "l\xF6schen"
  },
  portfolios: {
    remove: {
      success: {
        message: "{{ asset }}(s) aus Ihrem Portfolio entfernt"
      }
    }
  },
  request_download: {
    csv: "CSV herunterladen",
    message: "Anfrage erstellt"
  },
  add_to_portfolios: {
    adding_portfolio: {
      message: "Hinzuf\xFCgen von {{asset}}(s) zu Ihrem Portfolio",
      title: "Hinzuf\xFCgen"
    },
    success_add_portfolio: {
      message: "Hinzuf\xFCgen von {{asset}} zu Ihrem {{portfolio_state}}-Portfolio",
      title: "Erfolgreich"
    },
    success_create_portfolio: {
      title: "Erfolgreich",
      message: "Ein neues Portfolio wurde erstellt"
    },
    confrm_modal: {
      title: "Bereits enthaltene Wohnungen",
      buttons: {
        cancel: "Zur\xFCck",
        confirm: "Fortfahren"
      }
    },
    confirm_modal: {
      text_1: "Das Portfolio enth\xE4lt bereits Wohnung(en) von {{building_label}}.",
      text_2: "Willst du sie stattdessen mit dem Geb\xE4ude \xFCberschreiben?"
    },
    modal: {
      portfolio_name: "Portfolio-Name",
      add_to_existing: "Zu bestehendem hinzuf\xFCgen",
      name: "Name",
      create_button: "Erstellen",
      new: "Neu"
    },
    title: "Zum Portfolio hinzuf\xFCgen"
  }
};
var common = {
  assets: {
    pascalcase: "Verm\xF6genswerte"
  },
  comparables: {
    pascalcase: "Vergleichbare"
  },
  real_estate_firm: {
    pascalcase: "Immobilienfirma"
  },
  foundation: {
    pascalcase: "Investmentstiftung"
  },
  unlisted_fund: {
    pascalcase: "Unlisted fund"
  },
  listed_fund: {
    pascalcase: "Gelisteter Fonds"
  },
  transactions: {
    pascalcase: "Transaktionen"
  },
  keys: "schl\xFCssel",
  key: "Schl\xFCssel",
  enterprise_state: {
    state: "Vertragsart",
    ended: "Beendet",
    exempt: "Ausgenommen",
    trial_end: "Ende der Probezeit",
    unpaid: "Unbezahlt",
    subscribed: "Abonniert",
    trial: "Gericht",
    examination: "Pr\xFCfung"
  },
  developer_mode: {
    deactivated: "Deaktiviert",
    activated: "Aktiviert",
    state: "Entwicklermodus"
  },
  institutions: {
    pascalcase: "Institutionen"
  },
  connection_lost: {
    reload_button: "Neu laden",
    message_2: "Die Anwendung kann nicht wie erwartet funktionieren",
    message_3: "Bitte laden Sie die Seite neu.",
    title: "Verbindung verloren",
    message_1: "Du bist m\xF6glicherweise nicht mehr mit dem Internet verbunden"
  },
  warning_msg: "Operation an {{ failed }} ist fehlgeschlagen.",
  warning: "Warnung",
  financials: {
    array: {
      total: "Gesamt",
      residential: "Wohn-",
      office: "B\xFCro",
      commercial: "Kommerziell",
      industrial: "Industriell"
    }
  },
  edit: "Editieren",
  assign_contact_user: "Als Kontaktbenutzer zuweisen",
  create: "Erstellen",
  slot: {
    consumer: "Verbraucher",
    institutional: "Institutionell",
    investor: "Investor"
  },
  role: {
    user: "Benutzer",
    manager: "Manager",
    owner: "Besitzer"
  },
  removing: "entfernen",
  error: "Es ist etwas passiert",
  success: "Erfolgreich",
  delete_user: "Benutzer l\xF6schen",
  cancel_button: "Stornieren",
  confirm_button: "Best\xE4tigen",
  notification: {
    success: {
      title: "Erfolg"
    }
  },
  lots: {
    pascalcase: "Plots",
    lowercase: "Plots"
  },
  error_message: "Etwas ist passiert",
  buildings: {
    lowercase: "Geb\xE4ude",
    pascalcase: "Geb\xE4ude"
  },
  dwellings: {
    lowercase: "Wohnungen",
    pascalcase: "Dwellings"
  },
  portfolios: {
    pascalcase: "Portfolios"
  },
  funds: {
    pascalcase: "Fonds"
  }
};
var income_statement = {
  label: {
    r_provisions_for_future_works: "Vorkehrungen f\xFCr zuk\xFCnftige Arbeiten",
    r_unrealised_gains_and_losses_including_var_est_liq_tax: "Nicht realisierte Gewinne/Verluste einschlie\xDFlich der Variation der Liquidationssteuern",
    r_absolute_custodian_bank_commission_fees: "Absolute Verwahrstellenkommission Geb\xFChren",
    r_absolute_property_management_fees: "Absolute Immobilienverwaltungsgeb\xFChren",
    r_absolute_directors_fees: "Absolute Verg\xFCtung f\xFCr Direktoren",
    r_absolute_legal_fees: "Absolute Rechtsgeb\xFChr",
    r_net_income_available_for_distribution: "Nettoergebnis verf\xFCgbar zur Aussch\xFCttung",
    r_absolute_custodian_bank_distribution_fees: "Absolute Verwahrstellenvertriebsgeb\xFChren",
    r_interest_on_building_rights: "Interesse an Baurechten",
    r_total_provisions: "Gesamtvorr\xE4te",
    r_provisions_for_future_work: "Vorkehrungen f\xFCr zuk\xFCnftige Arbeiten",
    r_provisions_for_doubtful_debtors: "R\xFCckstellungen f\xFCr zweifelhafte Forderungen",
    r_ordinary_maintenance_and_repairs_expenses: "Regul\xE4re Wartungs- und Reparaturkosten",
    r_extraordinary_maintenance_and_repairs_expenses: "Au\xDFerordentliche Wartungs- und Reparaturkosten",
    r_administration_expenses: "Verwaltungskosten",
    r_other_administration_expenses: "Sonstige Verwaltungskosten",
    r_total_expenses: "Gesamtausgaben",
    r_total_revenue: "Gesamtumsatz",
    other_net_income_available_for_distribution: "Anderes Nettoeinkommen, das zur Verteilung zur Verf\xFCgung steht",
    r_capital_distribution: "Kapitalverteilung",
    r_dividend_distribution: "Dividendenverteilung",
    r_retained_earnings: "Gewinnr\xFCcklagen",
    r_ordinary_income_carried_forward_from_previous_financial_year: "\xDCblicher Ertrag, der aus dem vorherigen Gesch\xE4ftsjahr vorgetragen wird",
    r_net_income: "Einkommen nach Steuern",
    r_realised_capital_gains_and_losses: "Realisierte Kapitalgewinne und -verluste",
    r_unrealised_capital_gains_and_losses: "Nicht realisierte Kapitalgewinne und -verluste",
    r_realised_income: "Eingetragenes Einkommen",
    r_total_income: "Gesamteinkommen",
    r_other_expenses: "Andere Ausgaben",
    r_absolute_custodian_bank_fees: "Absolute Verwahrstellengeb\xFChren",
    r_market_maker_fees: "Marktmachergeb\xFChren",
    r_property_management_expenses: "Immobilienverwaltungskosten",
    r_property_tax: "Grundsteuer",
    r_profit_and_capital_taxes: "Gewinn- und Kapitalsteuern",
    r_taxes: "Steuern",
    r_administration_of_buildings: "Verwaltung von Geb\xE4uden",
    r_valuation_expenses: "Bewertungskosten",
    r_auditing_expenses: "Aufwendungen pr\xFCfen",
    r_valuation_and_auditing_expenses: "Bewertungs- und Pr\xFCfkosten",
    r_absolute_management_fees: "Absolute Verwaltungsgeb\xFChren",
    r_rental_income: "Mieteinnahmen",
    r_revenue_from_postal_and_bank_accounts: "Einnahmen aus Post- und Bankkonten",
    r_negative_interest: "Negative Zinsen",
    r_other_revenue: "Andere Einnahmen",
    r_mortgage_interest: "Hypothekenzinsen",
    r_maintenance_and_repairs_expenses: "Wartungs- und Reparaturkosten",
    r_other_interest: "Andere Interessen",
    r_total_interest: "Gesamtzins",
    r_building_expenses: "Geb\xE4udekosten",
    r_maintenance_costs: "Wartungskosten",
    total_net_income_available_for_distribution: "Gesamtes Nettoeinkommen, das zur Verteilung verf\xFCgbar ist",
    total_expenses: "Totalausgaben",
    total_revenues: "Gesamteinnahmen",
    retained_earnings: "Einbehaltene Gewinne",
    rental_income: "Mieteinnahmen",
    revenue_from_postal_and_bank_accounts: "Umsatz aus Post- und Bankkonten",
    negative_interest: "Negative Zinsen",
    other_revenue: "Andere Einnahmen",
    interest_expenses: "Zinsaufwendungen",
    maintenance_costs: "Instandhaltungskosten",
    taxes: "Steuern",
    valuation_and_auditing_expenses: "Bewertung und Pr\xFCfkosten",
    other_expenses: "Sonstige Ausgaben",
    net_income: "Einkommen nach Steuern",
    realised_gains_losses: "Realisierte Gewinne/Verluste",
    realised_income: "Einkommen realisiert",
    unrealised_gains_losses_including_liquidation_taxes_variation: "Nicht realisierte Gewinne/Verluste einschlie\xDFlich der Variation der Liquidationssteuern",
    total_income: "Gesamteinkommen",
    ordinary_income_carried_forward: "Ordentliches Einkommen vorgetragen",
    capital_distribution: "Kapitalverteilung",
    dividend_distribution: "Dividendenverteilung",
    year: "Jahr"
  },
  legend: {
    group: {
      distribution_of_realised_income: "Verteilung des realisierten Einkommens",
      income_statement: "Einkommens\xFCbersicht"
    }
  },
  unit: {
    chf: "CHF"
  }
};
var asset_page = {
  environmental: {
    co2: {
      total_co2_scope_1_2_33: "Gesamt CO\u2082 (Bereich 1 + 2 + 3.3)",
      total_co2_scope_1_2: "Total CO\u2082 (Bereich 1 + 2)",
      co2_scope_33_per_m2: "CO\u2082 (Bereich 3.3)",
      co2_scope_2_per_m2: "CO\u2082 (Scope 2)",
      co2_scope_1_per_m2: "CO\u2082 (Bereich 1)",
      co2_scope_1_2_33_per_m2: "CO\u2082 (Umfang 1 + 2 + 3.3)",
      co2_scope_1_2_per_m2: {
        key: "CO\u2082 (Bereich 1 + 2)",
        description: "CO\u2082-Emissionen werden berechnet, indem die Energieintensit\xE4t mit dem REIDA-Koeffizienten f\xFCr jedes Geb\xE4ude multipliziert wird."
      }
    },
    idc: {
      idc_per_m2: {
        description: "Energieintensit\xE4t misst den gesch\xE4tzten Energieverbrauch pro Quadratmeter Heizfl\xE4che \xFCber ein Jahr des Geb\xE4udes.",
        key: "Energieintensit\xE4t"
      },
      total_idc: "Gesamtenergie"
    },
    risk: {
      chart: {
        description: "Der Klimarisikoindex wird auf der Grundlage der Exposition gegen\xFCber verschiedenen Umweltgefahren berechnet. Jedes Risiko hat im Index das gleiche Gewicht.",
        suffix: "/10"
      },
      title: "Risiken",
      risk: {
        title: "Umweltrisiken"
      },
      climate_risk_index: {
        title: "Klima-Risiko-Index"
      },
      overbank_sedimentation: "\xDCberflutungsablagerung",
      debris_flow: "Tr\xFCmmerstrom",
      rockfalls: "Felsst\xFCrze",
      sia261: "Seismische Zone SIA 261",
      hail_hazard_10_years_average: "Hagelgr\xF6\xDFe",
      avalanches: "Avalanches",
      landslides: "Erdrutsche",
      floods_50_years: "\xDCberschwemmungen"
    },
    energy: {
      idc: {
        estimated_idc: "Gesch\xE4tzte Energieintensit\xE4t",
        total_official_idc: "Gesamtenergieintensit\xE4t (offiziell)",
        estimated_idc_per_m2: "Energieintensit\xE4t pro m\xB2",
        total_estimated_idc: "Gesamte Energieintensit\xE4t",
        official_idc_per_m2: "Energetische Intensit\xE4t pro m\xB2 (offiziell)",
        title: "Energieintensit\xE4t"
      },
      co2: {
        estimated_co2: "Gesch\xE4tztes CO\u2082",
        official_co2_emission_per_m2: "CO\u2082 pro m\xB2 (offiziell)",
        total_official_co2_emission: "Gesamt CO\u2082 (offiziell)",
        estimated_co2_emission_per_m2: "CO\u2082 pro m\xB2 (gesch\xE4tzt)",
        total_estimated_co2_emission: "Gesamt CO\u2082 (gesch\xE4tzt)",
        title: "CO\u2082-Emissionen"
      },
      title: "Energie",
      heating: {
        energy_generator_water_1: "Wassererhitzer",
        energy_source_water_1: "Wasserenergiequelle",
        energy_source_heating_1: "Heizenergiequelle",
        energy_generator_heating_1: "Heizgenerator",
        title: "Heizungsproduktion"
      },
      solar: {
        solar_existing: "Installierte Solarenergie",
        solar_potential: "Solarpotenzial",
        title: "Solar"
      }
    },
    title: "Umwelt",
    environment: {
      climate: {
        title: "Klima",
        temperature: {
          title: "Durchschnittstemperatur"
        },
        sunlight: {
          title: "Durchschnittliches Sonnenlicht"
        },
        rain: {
          title: "Durchschnittlicher Niederschlag"
        }
      },
      pollution: {
        rail_noise_day: {
          label: "Tag Zugger\xE4usch"
        },
        rail_noise_night: {
          label: "Nachzugger\xE4usch"
        },
        road_noise_day: {
          label: "Tagverkehrsl\xE4rm"
        },
        road_noise_night: {
          label: "Nachtverkehrsl\xE4rm"
        },
        title: "L\xE4rmbel\xE4stigung"
      },
      title: "Umwelt"
    }
  },
  social: {
    income_and_taxes: {
      income: {
        description: "Beschreibung",
        subject: "Einkommen pro Steuerzahler",
        title: "Einkommen",
        label: "Einkommen"
      },
      profit: {
        label: "Gewinn"
      },
      capital: {
        label: "Kapital"
      },
      wealth: {
        label: "Reichtum"
      },
      municipality_tax: {
        title: "Gemeinde-Steuermultiplikatoren"
      },
      canton_tax: {
        title: "Kantonale Steuer-Multiplikatoren"
      },
      title: "Einkommen und Steuern"
    },
    transportation_accessibility: {
      transport_accessibility: {
        description: "Barrierefreiheitsbewertung nach \xF6ffentlichen Verkehrsmitteln gem\xE4\xDF der ARE",
        label: "Barrierefreiheit mit \xF6ffentlichen Verkehrsmitteln",
        title: "Mit dem Zug"
      },
      road_accessibility: {
        description: "Zug\xE4nglichkeitspunktzahl nach Stra\xDFe gem\xE4\xDF der ARE",
        label: "Barrierefreiheit per Stra\xDFe",
        title: "Auf der Stra\xDFe"
      },
      title: "Zug\xE4nglichkeit"
    },
    well_being: {
      public_utility: {
        key: "\xD6ffentliches Versorgungsgeb\xE4ude",
        title: "\xD6ffentliches Versorgungsgeb\xE4ude"
      },
      title: "Wohlbefinden"
    },
    density: {
      population_density: {
        title: "Bev\xF6lkerungsdichte",
        description: "Die Bev\xF6lkerungsdichte misst die durchschnittliche Bev\xF6lkerungsdichte in den umliegenden Hektar, gem\xE4\xDF STATPOP",
        key: "Bev\xF6lkerungsdichte"
      },
      title: "Dichte",
      green_density: {
        description: "Die gr\xFCne Dichte misst den Anteil der umliegenden Hektar, die einen Gro\xDFteil der Gr\xFCnfl\xE4che enthalten, gem\xE4\xDF der Fl\xE4chennutzung (NOAS04)",
        title: "Gr\xFCnfl\xE4chen",
        key: "Gr\xFCne Dichte"
      },
      job_density: {
        title: "Jobdichte",
        description: "Die Arbeitsplatzdichte misst die durchschnittliche Arbeitsplatzdichte in den umliegenden Hektar, gem\xE4\xDF STATENT",
        key: "Jobdichte"
      },
      occupancy_per_bedroom: {
        subject: "Einwohner",
        title: "Belegung pro Schlafzimmer"
      },
      occupancy_per_m2: {
        subject: "Einwohner"
      },
      occupancy_per_100_m2: {
        title: "Belegung pro 100 m\xB2"
      },
      occupancy: {
        per_bedroom: "Pro Schlafzimmer",
        per_meter: "Pro 100 m\xB2",
        title: "Belegung"
      },
      migration: {
        departure: "Abreise pro Einwohner",
        arrival: "Ankunft pro Einwohner",
        title: "Migration"
      },
      income: {
        subject: "Einkommen pro Steuerzahler",
        title: "Einkommen pro Steuerzahler"
      }
    },
    accessibility: {
      chart: {
        suffix: "/10",
        subject: "Index",
        title: "Zug\xE4nglichkeit"
      },
      goods_and_services: {
        title: "Andere"
      },
      transportation: {
        title: "\xD6ffentliche Verkehrsmittel"
      },
      health_and_edu: {
        title: "Gesundheit & Bildung"
      },
      title: "Entfernungen zu Waren und Dienstleistungen",
      distance: {
        title: "Barrierefreiheit"
      },
      transport_bus: "Bus",
      transport_train: "Zug",
      restaurants: "Restaurants",
      shops: "Gesch\xE4fte",
      secondary_education: "Sekundarstufe",
      primary_education: "Primarbildung",
      healthcare: "Gesundheitswesen",
      early_years: "Fr\xFChe Jahre",
      cultural_venues: "Kulturelle Veranstaltungsorte",
      aged_care: "Altenpflege"
    },
    demographic: {
      migration: {
        title: "Migration"
      },
      emigration: {
        subject: "Emigration"
      },
      immigration: {
        subject: "Einwanderung"
      },
      death: {
        subject: "Todesf\xE4lle"
      },
      birth: {
        subject: "Geburten"
      },
      vital_statistics: {
        title: "Vitalstatistiken"
      },
      civil_status: {
        death: "Tod p.a.",
        birth: "Geburten p.a.",
        divorce: "Scheidung p.a.",
        marriage: "Heirats p.a.",
        household: "Durchschnittliche Haushaltsgr\xF6\xDFe",
        title: "Zivilstand"
      },
      foreigners: {
        title: "Ausl\xE4nder"
      },
      gender: {
        title: "Geschlecht"
      },
      ages: {
        title: "Zeitalter"
      },
      title: "Demografisch"
    },
    title: "Sozial"
  },
  financials: {
    units: {
      commercial_rent: "CHF",
      residential_rent: "CHF",
      total_rent: "CHF"
    },
    ratio_metric: {
      market_value_reported: "Marktwert ",
      commercial_spaces: {
        label: "Gewerbliche R\xE4ume"
      },
      dwellings: {
        label: "Dwellings"
      },
      total_tenant_charges: "Gesamte Mietergeb\xFChren",
      market_value: "Marktwert",
      charges_per_m2: "Mietergeb\xFChren pro m\xB2",
      price_per_m2: "Preis pro m\xB2",
      rent_per_m2: "Mieteinnahmen pro m\xB2",
      charges: {
        title: "Geb\xFChren"
      },
      average: "Durchschnitt",
      industrial: "Industriell",
      office: "Buero",
      commercial: "Kommerziell",
      residential: "Wohn-",
      rent: {
        title: "Mieteinnahmen"
      },
      price: {
        title: "Preis"
      },
      title: "Ma\xDFe pro Quadratmeter"
    },
    unit: {
      chf: "CHF"
    },
    charges: {
      title: "Mietergeb\xFChren"
    },
    total: {
      label: "Gesamt"
    },
    commercial_spaces: {
      label: "Gewerbliche R\xE4ume"
    },
    dwellings: {
      label: "Wohnungen"
    },
    performance: {
      title: "Leistung",
      rental_income: "Mieteinnahmen"
    },
    metrics: {
      title: "Kennzahlen pro Quadratmeter"
    },
    tile: {
      charges: "Mietergeb\xFChren",
      evolution: "Evolution",
      rental_income: "Mieteink\xFCnfte"
    },
    revenue: {
      title: "Einnahmen"
    },
    comparables: {
      title: "Vergleichbare"
    },
    title: "Finanzen"
  },
  description: {
    tile: {
      parent_description: "Elternbeschreibung",
      buildings: {
        parent_description: "Lotbeschreibung"
      },
      usage: "Verwendung",
      geometry: "Geometrie",
      dwellings: {
        parent_description: "Elternbeschreibung erstellen"
      },
      description: "<text>Beschreibung</text>",
      location: "Standort",
      addresses: "Adressen",
      administrative_information: "Administrative Informationen",
      amenities: "Einrichtungen"
    },
    title: "Beschreibung",
    identification: {
      title: "Identifikation"
    },
    general_information: {
      title: "Allgemeine Informationen"
    }
  },
  valuation: {
    cap_rate_method: {
      income_inputs: {
        other: {
          suffix: "CHF",
          label: "Sonstige Einnahmen"
        },
        parking: {
          label: "Parken",
          suffix: "CHF"
        },
        title: "Einkommensquellen",
        office_net_income: {
          label: "Bu\u0308ro Nettogewinn",
          suffix: "CHF"
        },
        commercial: {
          label: "Einkommen aus Gewerbebetrieb",
          suffix: "CHF"
        },
        industrial: {
          label: "Industriell",
          suffix: "CHF"
        },
        residential_net_income: {
          suffix: "CHF",
          label: "Nettoeinkommen aus Wohnraum"
        },
        dwelling_net_rent: {
          label: "Wohnnettomiete",
          suffix: "CHF"
        }
      },
      cap_rate_inputs: {
        title: "Kapitalisierungsraten-Eingaben",
        capitalization_rate: {
          label: "Kapitalisierungsrate",
          suffix: "%"
        }
      },
      result: {
        total_acquisition_cost: {
          suffix: "CHF",
          label: "Gesamterwerbskosten"
        },
        purchase_price: {
          label: "Kaufpreis",
          suffix: "CHF"
        },
        title: "Ergebnis"
      },
      title: "Kapitalisierungsrate-Methode",
      description: {
        content: "Die Kapitalisierungsrate (Cap Rate) Methode, die auf Immobilien angewendet wird, dient zur Sch\xE4tzung des potenziellen Wertes einer Immobilie. Sie wird berechnet, indem das Mieteinkommen durch die angestrebte Rendite geteilt wird. Cap Rates ber\xFCcksichtigen keine zuk\xFCnftige Wertsteigerung oder Finanzierungskosten.",
        title: "<text>Beschreibung</text>"
      }
    },
    parameters: {
      buyer_seller: {
        other_fees_chf: {
          suffix: "CHF",
          label: "Andere Geb\xFChren"
        },
        other_fees_pts: {
          suffix: "%",
          label: "Andere Geb\xFChren"
        },
        asset_manager_fees: {
          label: "Geb\xFChren f\xFCr Asset-Manager",
          suffix: "%"
        },
        share_buyers_seller: {
          label: "Share K\xE4ufer / Verk\xE4ufer",
          suffix: "%"
        },
        title: "K\xE4ufer / Verk\xE4ufer"
      },
      transaction_fees: {
        property_transfert_fees: {
          suffix: "%",
          label: "Eigentums\xFCbertragungsgeb\xFChren"
        },
        title: "Transaktionsgeb\xFChren",
        land_registration_fees: {
          label: "Grundbuchgeb\xFChren",
          suffix: "%"
        },
        notary_fees: {
          label: "Notarkosten",
          suffix: "%"
        }
      },
      due_diligence_cost: {
        other_valuations: {
          suffix: "CHF",
          label: "Andere Bewertungen"
        },
        valuation: {
          suffix: "CHF",
          label: "Bewertung"
        },
        title: "Due Diligence Kosten"
      },
      title: "Parameter"
    },
    qh_method: {
      title: "Quanthome's Methode",
      result: {
        total_acquisition_cost: {
          label: "Gesamterwerbskosten",
          suffix: "CHF"
        },
        purchase_price: {
          suffix: "CHF",
          label: "Kaufpreis"
        },
        title: "Ergebnis"
      },
      description: {
        title: "Beschreibung",
        content: "Die Machine-Learning-Algorithmen von Quanthome sch\xE4tzen die erwartete Zielrendite f\xFCr das Geb\xE4ude und prognostizieren einen Wert basierend auf den gesch\xE4tzten Cashflows."
      }
    },
    title: "Bewertung"
  },
  governance: {
    item: {
      ownership: {
        link: "link",
        registry: "Grundbuch",
        owner: "Besitzer"
      },
      certification: {
        minergy: "Minergie"
      },
      management: {
        website: "Website",
        name: "Name"
      }
    },
    tile: {
      certification: "Zertifizierung",
      management: "Immobilienverwaltung",
      ownership: "Eigentum"
    },
    organisation: {
      title: "Organisation"
    },
    title: "Governance"
  },
  overview: {
    description: {
      market_value: "Marktwert",
      idc: "Energieintensit\xE4t",
      net_rent: "Mieteink\xFCnfte",
      co2: "CO\u2082",
      roa: "ROA",
      charges: "Mietergeb\xFChren"
    }
  },
  assets: {
    title: "Inventar"
  }
};
var portfolios = {
  environmental: {
    evolution: {
      chart: {
        title: "Diagramm"
      }
    },
    estimated_co2_emission_per_m2: "CO\u2082 pro m\xB2 (gesch\xE4tzt)",
    total_estimated_co2_emission: "Gesamt CO\u2082 (gesch\xE4tzt)",
    estimated_idc_per_m2: "Energetische Intensit\xE4t pro m\xB2",
    total_estimated_idc: "Gesamte Energieintensit\xE4t",
    overbank_sedimentation: "Uferbank-Sedimentation",
    rockfalls: "Felsst\xFCrze",
    solar_potential: "Gesamtes solares Potenzial",
    floods_50_years: "\xDCberschwemmungen",
    building_with_solar_existing: "Geb\xE4ude mit Solarpanels",
    solar_existing_per_m2: "Installierte Solarleistung pro m\xB2",
    debris_flow: "Tr\xFCmmerstrom",
    landslides: "Landschaften",
    solar_existing: "Installierte Solarenergie",
    avalanches: "Lawinen",
    hail_hazard_10_years_average: "Hagelgr\xF6\xDFe"
  },
  financials: {
    evolution: {
      chart: {
        title: "Diagramm"
      },
      title: "Evolution"
    },
    performance: {
      evolution: {
        title: "Evolution"
      },
      title: "Leistung"
    }
  },
  environment: {
    energy_distribution: {
      value_string: "Heizbereich",
      label_string: "Heizquelle"
    }
  },
  description: {
    distribution: {
      value_string: "Bereich",
      label_string: "Kanton"
    }
  },
  noright: {
    button: "Kontaktieren Sie den Account-Manager",
    details: "Das Portfolio-Modul erm\xF6glicht Ihnen eine globale Sicht auf ein Immobilienportfolio, von finanziellen bis zu ESG-Daten.",
    subtitle: "Dieses Feature erfordert einen umfassenderen Plan.",
    title: "Portfolios"
  },
  overview: {
    type: {
      empty: "Leer",
      dwelling: "Wohnung",
      mixed: "Gemischt",
      building: "Geb\xE4ude"
    },
    potential_roa: "ROA",
    potential_charges: "Geb\xFChren",
    potential_net_rent: "Mieteinnahmen",
    potential_market_value: "Marktwert"
  },
  modal: {
    confirm_delete: {
      notification: {
        text: "Das Portfolio wurde gel\xF6scht"
      },
      cancel: "Zur\xFCck",
      proceed: "Fortfahren",
      text: "Bist du sicher, dass du dieses Portfolio l\xF6schen m\xF6chtest?",
      title: "L\xF6schen Sie das Portfolio"
    }
  },
  create_modal: {
    title: "Neues Portfolio"
  },
  notification: {
    success_create: {
      message: "Das Portfolio wurde erfolgreich erstellt",
      title: "Erfolgreich erstellt"
    }
  },
  widget: {
    energy: {
      title: "Energie"
    },
    risk: {
      title: "Risiko"
    },
    identification: {
      title: "Identifizierung"
    }
  },
  tiles: {
    solar: {
      title: "Solar"
    },
    energy_source: {
      title: "Energiequelle"
    },
    risk_index: {
      title: "Klimarisikoindex"
    },
    risks: {
      title: "Umweltrisiken"
    },
    composition: {
      title: "Komposition"
    },
    designation: {
      title: "Bezeichnung"
    },
    distribution: {
      title: "Verteilung"
    },
    idc_and_co2: {
      title: "Energiemenge & CO\u2082"
    }
  },
  designation: {
    created_at: "Erstellungsdatum",
    updated_at: "Letzte Aktualisierung",
    name: "Name des Instruments",
    fund_age: "Alter des Immobilienportfolios",
    commercial_area: "Gesamtverkaufsfl\xE4che",
    industrial_area: "Gesamtindustriegebiet",
    individual_dwellings_nb: "Anzahl der einzelnen Wohnungen",
    office_area: "Gesamtb\xFCrofl\xE4che",
    dwellings_nb: "Anzahl der Wohnungen",
    buildings_nb: "Anzahl der Geb\xE4ude",
    total_area: "Totalfl\xE4che",
    residential_area: "Gesamtwohnfl\xE4che",
    type: "Verm\xF6genszusammensetzung des Portfolios"
  }
};
var portfolio = {
  environmental: {
    evolution: {
      title: "Evolution"
    }
  }
};
var candle_stick_chart = {
  unit: {
    units: "Einheiten",
    chf: "CHF"
  },
  label: {
    close: "Schlie\xDFen",
    high: "Hoch",
    low: "Niedrig",
    open: "Offen",
    start_date: "Beginn",
    end_date: "Ende",
    volume: "Volumen",
    turnover: "Umsatz",
    date: "Datum"
  }
};
var screener = {
  filters: {
    labels: {
      potential_net_rent_per_m2: "Potenzielle Nettomiete pro m\xB2",
      potential_market_value: "Potenzieller Marktwert (gesch\xE4tzt)",
      potential_market_value_per_m2: "Potenzieller Marktwert pro m\xB2",
      potential_charges: "Potenzielle Geb\xFChren",
      potential_charges_per_m2: "Potenzielle Kosten pro m\xB2",
      potential_roa: "ROA",
      area: "Handlungsfl\xE4che",
      potential_net_rent: "Potentielle Nettomiete",
      undefined: "-",
      columns_to_ignore: "Spalten",
      sort: "Sortieren",
      roa: "ROA",
      charges: "Mietergeb\xFChren",
      price: "Preis",
      rent: "Mieteinnahmen",
      net_rent: "Mieteinnahmen",
      idc: "Energiest\xE4rke",
      co2: "CO\u2082",
      label: "Label",
      energy_source_heating: "Heizquelle",
      rooms_nb: "Zimmernummer",
      floor: "Boden",
      elevator: "Aufzug",
      ground_area: "Grundfl\xE4che",
      floors_nb: "Stockwerknummer",
      dwellings_nb: "Anzahl der Wohnungen",
      construction_year: "Baujahr",
      buildings_nb: "Geb\xE4udenummer",
      plot_surface: "Plotfl\xE4che",
      municipality: "Gemeinde",
      street: "Stra\xDFe",
      locality: "Ortschaft",
      region: "Region",
      canton: "Kanton"
    },
    multi_select: {
      options: {
        mixed: "Gemischt",
        residential: "Wohn-",
        commercial: "Kommerziell",
        listed_fund: "Aufgelisteter Fonds",
        unlisted_fund: "Unlisted fund",
        real_estate_firm: "Immobilienfirma",
        foundation: "Stiftung"
      }
    },
    groups: {
      information: "Information"
    },
    panel: {
      show: "Zeigen"
    }
  },
  tabs: {
    legal_forms: "Rechtsformen",
    performance: "Leistung",
    accounting: "Buchhaltung",
    stock: "Aktienkursentwicklung",
    fees: "Geb\xFChren",
    esg: "ESG",
    transaction: "Transaktion",
    information: "Information",
    structure: "Struktur",
    trading: "Handel",
    governance: "Governance",
    social: "Sozial",
    saved_screens: "Gespeicherte Bildschirme",
    type: "Art",
    financial: "Finanziell",
    energy: "Energie",
    dimension: "Dimension",
    location: "Standort"
  }
};
var setting = {
  preference: {
    notification: {
      unsaved_screen: {
        sub: "Erhalten Sie eine Benachrichtigung, wenn Sie einen nicht gespeicherten Bildschirm haben.",
        title: "Aktivieren Sie die Benachrichtigung f\xFCr nicht gespeicherte Bildschirme"
      },
      sub: "Aktivieren Sie die erforderliche Popup-Benachrichtigung.",
      title: "Benachrichtigungs-Popup-Pr\xE4ferenz"
    },
    save_changes: "\xC4nderungen speichern",
    unsaved_screen_notification: {
      sub: "Wenn Sie dieses K\xE4stchen aktivieren, erhalten Sie diese Benachrichtigung nicht mehr. Sie k\xF6nnen diese Einstellung auf der Einstellungsseite zur\xFCck\xE4ndern.",
      title: "Zeig mir diese Benachrichtigung nicht mehr an"
    },
    language: {
      subtitle: "W\xE4hlen Sie Ihre bevorzugte Sprache.",
      title: "Sprache",
      success_message: "Die Sprachpr\xE4ferenz wurde gespeichert."
    }
  },
  profile: {
    email_preferences: {
      technical: {
        sub: "Erhalte \xC4nderungsprotokolle, wenn die App neue Funktionen hat",
        title: "Technische E-Mails"
      },
      marketing: {
        sub: "Neuigkeiten \xFCber das Unternehmen erhalten",
        title: "Marketing-E-Mails"
      },
      sub: "\xC4ndern Sie Ihre E-Mail-Pr\xE4ferenzen",
      title: "E-Mail-Einstellungen"
    },
    notification: {
      send_email_duplicated_msg: "Eine Best\xE4tigungs-E-Mail wurde innerhalb einer Minute gesendet, bitte \xFCberpr\xFCfen Sie Ihr Postfach.",
      send_email_success_msg: "Eine Best\xE4tigungs-E-Mail wurde gesendet, bitte \xFCberpr\xFCfen Sie Ihr Postfach.",
      wrong_first_name_msg: "Bitte geben Sie Ihren korrekten Vornamen ein.",
      change_pp_success_msg: "Ihr Profilbild wurde erfolgreich ge\xE4ndert.",
      error: "Etwas ist passiert",
      success: "Erfolgreich"
    },
    picture: {
      format_error: "Wahrscheinlich falsches Format oder Gr\xF6\xDFe.",
      error: "Bild konnte nicht hochgeladen werden"
    },
    confirm_crop: "Best\xE4tigen",
    email_verification_note: {
      button: "Best\xE4tigungscode erneut senden",
      msg: "Bitte verifizieren Sie Ihre E-Mail, bevor Sie die Plattform nutzen.",
      title: "\xDCberpr\xFCfen Sie Ihre E-Mail"
    },
    confirm_delete_pp: {
      delete_button: "Account l\xF6schen",
      cancel_button: "Stornieren",
      msg: "Um zu best\xE4tigen, dass Sie Ihr Konto l\xF6schen m\xF6chten, geben Sie bitte Ihren Vornamen unten ein und klicken Sie auf die Schaltfl\xE4che Konto l\xF6schen.",
      modal_title: "Best\xE4tigen Sie das L\xF6schen"
    },
    click_to_upload_file_constraints: "oder ziehen Sie PNG, JPEG oder JPG hierher",
    click_to_upload: "Klicken Sie hier, um hochzuladen",
    save_changes: "\xC4nderungen speichern",
    delete_my_account_title_sub: "Es gibt kein Zur\xFCckkommen.",
    delete_my_account_title: "Mein Konto l\xF6schen",
    profile_picture_title_sub: "Lade dein Profilbild hoch.",
    profile_picture_title: "Profilbild",
    personal_info_title_sub: "Aktualisieren Sie Ihre pers\xF6nlichen Daten.",
    personal_info_title: "Pers\xF6nliche Informationen"
  },
  connections: {
    modal: {
      copy_error: "Fehler beim Kopieren. Bitte manuell kopieren",
      copied: "Der Schl\xFCssel wurde in die Zwischenablage kopiert",
      warning: {
        title: "Fehlende Werte",
        fill: "Bitte f\xFCllen Sie alle Felder aus"
      },
      delete: {
        subtitle: "Sind Sie sicher, dass Sie diese Schl\xFCssel l\xF6schen m\xF6chten? Diese Aktion kann nicht r\xFCckg\xE4ngig gemacht werden.",
        title: "Schl\xFCssel {{ count }} l\xF6schen"
      },
      delete_cancel: "Stornieren",
      delete_confirm: "L\xF6schen",
      button_copy: "Kopieren und schlie\xDFen",
      create: {
        subtitle: "Einmal erstellt, wird dieser Schl\xFCssel nie wieder sichtbar sein. Wenn er kompromittiert wird, muss der Schl\xFCssel gel\xF6scht und ein neuer erstellt werden.",
        title: "API-Schl\xFCssel erstellen"
      },
      name: {
        label: "Schl\xFCsselname",
        placeholder: "Beispielname"
      },
      expiration: {
        label: "G\xFCltigkeitsdauer",
        placeholder: "W\xE4hlen Sie eine Dauer"
      },
      button_create: "Schl\xFCssel erstellen"
    },
    options: {
      year: "{{ number }} Jahr",
      months: "{{ number }} Monate",
      days: "{{ number }} Tage",
      day: "{{ number }} Tag"
    },
    days: {
      error: "Das Ablaufdatum ist erforderlich"
    },
    name: {
      error: "Name ist zu kurz"
    },
    table_name: {
      app: "App-Schl\xFCssel",
      personnal: "Pers\xF6nliche Schl\xFCssel"
    },
    tokens_table: {
      col_name: {
        enterprise: "Unternehmen",
        user: "Benutzer",
        expires_at: "Verfallsdatum",
        created_at: "Erstellungsdatum",
        name: "Schl\xFCsselname"
      }
    }
  },
  seats: {
    payment_warning: {
      msg: {
        ended: "Ihr Vertrag ist abgelaufen. Haben Sie alle Vorteile unserer Dienstleistungen genutzt?",
        no_slot: "Ihnen wurde kein Slot zugewiesen. Bitte weisen Sie sich den entsprechenden Slot zu.",
        end_trial: "Ihre Testphase ist beendet. Haben Sie alle Vorteile unserer Dienstleistungen genutzt?",
        unpaid: "Ihr Unternehmen befindet sich \xFCberf\xE4llig. Ihr Abonnement muss geregelt werden.",
        owner: "Bitte kontaktieren Sie Ihren zust\xE4ndigen Account-Manager.",
        user: "Bitte kontaktieren Sie diesen Unternehmensmanager."
      },
      button: "Kontaktieren Sie den Kundenservice",
      title: "Es gab ein Problem mit Ihrer Zahlung"
    },
    member_table: {
      col_name: {
        last_activity_date: "Aktiv seit",
        right: "Rechte",
        role: "Rolle",
        name: "Name"
      }
    },
    no_slot_warning: {
      title: "Du hast keinen Plan"
    },
    delete_user: {
      multiple: {
        confirm_msg: "Sind Sie sicher, dass Sie diese {{number}} Benutzer l\xF6schen m\xF6chten?"
      },
      success_msg: "Der Benutzer wurde gel\xF6scht.",
      confirm_msg: "Bist du sicher, dass du diesen Benutzer {{firstName}} {{lastName}} l\xF6schen m\xF6chtest?"
    },
    am_table: {
      col_name: {
        phone: "Telefonnummer des Account Managers",
        email: "Account-Manager-E-Mail",
        name: "Account-Manager"
      }
    },
    plan_card: {
      info: "{{verwendet}} von {{gesamt}} Sitzpl\xE4tzen verwendet"
    },
    institutional: {
      name: "Institutionell"
    },
    investor: {
      name: "Investor"
    },
    consumer: {
      name: "Verbraucher"
    }
  },
  security: {
    link_device: {
      notice: "Wir werden Ihr aktuelles Ger\xE4t von Ihrem Konto trennen, damit Sie Ihr Konto mit einem neuen Ger\xE4t verkn\xFCpfen k\xF6nnen. Sie werden abgemeldet, um das neue Ger\xE4t zu verkn\xFCpfen. Der vorherige TOTP wird nicht mehr funktionieren. Bitte best\xE4tigen Sie, ob Sie mit der Operation fortfahren m\xF6chten.",
      subtitle: "Verkn\xFCpfen Sie Ihr Konto mit einem neuen Ger\xE4t, wenn Sie keinen Zugriff mehr auf das aktuell verkn\xFCpfte Ger\xE4t haben",
      title: "Link zu neuem Ger\xE4t"
    },
    warning_note: {
      content: "Es wird empfohlen, Ihre Wiederherstellungscodes regelm\xE4\xDFig neu zu generieren. F\xFChren Sie die Operation mit der Schaltfl\xE4che unten durch.",
      title: "Erstellen Sie Ihre Wiederherstellungscodes erneut"
    },
    renegerate_code: {
      success: "Neue Wiederherstellungscodes erfolgreich gespeichert.",
      title: "Erneuern Sie die Wiederherstellungscodes",
      subtitle: "Ihre vorherigen Wiederherstellungscodes werden nicht mehr funktionieren"
    },
    password: {
      disclaimer: "Das Passwort muss mindestens 8 Zeichen enthalten.",
      success_message: "Passwort erfolgreich aktualisiert !",
      error_message: "Passw\xF6rter m\xFCssen identisch sein",
      save_changes: "\xC4nderungen speichern",
      confirm: "Best\xE4tigen Sie das neue Passwort",
      subtitle: "\xC4ndern Sie Ihr Passwort",
      title: "Passwort"
    }
  },
  company: {
    set_contact_user: {
      success: "Der Benutzer wurde erfolgreich als Kontaktbenutzer festgelegt."
    }
  },
  organization: {
    details: {
      no_contact_name: "No Contact-Benutzer zugewiesen",
      contact_subtitle: "Referenzkommunikationsbenutzer",
      contact_name: "Benutzer kontaktieren",
      phone: "Telefonnummer",
      email: "Email-Adresse",
      zip: "ZIP",
      city: "Stadt",
      address: "Adresse",
      country: "Land",
      name: "Firmenname",
      subtitle: "Aktualisieren Sie die Details Ihres Unternehmens.",
      title: "Unternehmensinformationen"
    },
    notification: {
      update_success: "Updates wurden erfolgreich angewendet."
    },
    logo: {
      subtitle: "Aktualisieren Sie das Logo Ihres Unternehmens.",
      title: "Firmenlogo"
    }
  },
  collaborator: {
    modal: {
      link_companies: {
        success_msg: "Unternehmen, die von {{name}} verwaltet werden, wurden erfolgreich aktualisiert.",
        subtitle: "Unternehmen",
        title: "Unternehmen"
      }
    },
    col: {
      companies: "Unternehmen",
      invitation_process: "Einladungsprozess"
    },
    table_name: "Teammitglieder",
    reinvite_user: "Eine Einladungsemail wurde erfolgreich gesendet."
  },
  companies: {
    table_name: "Unternehmensmitglieder"
  }
};
var unsaved_screener = {
  tooltip: {
    save_button: "Speichern Sie Ihre nicht gespeicherten \xC4nderungen mit diesem Speichern-Button"
  },
  warning: {
    content: "Sie haben einen laufenden, nicht gespeicherten Bildschirm."
  },
  modal: {
    title: "Sie sind dabei, die Screener-Seite zu verlassen"
  },
  button: {
    back_to_screener: "Gehe zur\xFCck zum Screener",
    leave_page: "Fortfahren ohne zu speichern"
  }
};
var management = {
  nav: {
    preferences: {
      subtitle: "Verwalten Sie Ihre Einstellungen",
      title: "Pr\xE4ferenzen"
    },
    connections: {
      subtitle: "Erstellen und verwalten Sie API-Schl\xFCssel, um auf quanthome-Daten von einem Betriebssystem, einer Anwendung oder einem anderen Dienst zuzugreifen.",
      title: "API"
    },
    edit_company: {
      subtitle: "Aktualisieren Sie die Informationen eines Unternehmens",
      title: "Aktualisieren Sie das Unternehmen"
    },
    create_company: {
      subtitle: "Richten Sie ein neues Unternehmen ein, indem Sie die meisten seiner Informationen bereitstellen",
      title: "Neue Firma"
    },
    company: {
      subtitle: "Mitglieder und Sitz eines Unternehmens verwalten.",
      title: "Unternehmen"
    },
    requests: {
      subtitle: "Alle Anfragen werden hier gesammelt.",
      title: "Anfragen"
    },
    contracts: {
      title: "Vertr\xE4ge"
    },
    users: {
      subtitle: "Alle Benutzer aller Unternehmen versammeln sich hier.",
      title: "Benutzer"
    },
    teams: {
      subtitle: "Hier ist alles, was Sie \xFCber die Personen wissen m\xFCssen, die auf die Plattform in Ihrem Unternehmen zugreifen.",
      title: "Teams"
    },
    companies: {
      title: "Unternehmen",
      subtitle: "Hier sind alle Unternehmen, die die Plattform abonnieren."
    },
    profile: {
      subtitle: "Aktualisieren Sie Ihre Profileinstellungen.",
      title: "Profil"
    },
    security: {
      subtitle: "Verwalten Sie Ihr Passwort, um auf die Plattform zuzugreifen.",
      title: "Sicherheit"
    },
    languages: {
      subtitle: "Verwalten Sie Ihre Sprachen"
    },
    language: {
      title: "Sprache"
    },
    organization: {
      subtitle: "Hier sind alle Informationen \xFCber das Unternehmen, in dem Sie arbeiten.",
      title: "Organisation"
    },
    seats: {
      subtitle: "Hier ist alles, was Sie \xFCber Sitze und Abrechnung wissen m\xFCssen",
      title: "Sitze"
    },
    collaborators: {
      subtitle: "Hier ist alles, was Sie \xFCber die Personen wissen m\xFCssen, die auf die Plattform in Ihrem Unternehmen zugreifen.",
      title: "Zusammenarbeiter"
    }
  },
  email: {
    addresses: {
      empty: "Ihre Benutzer werden hier angezeigt",
      description: "Mach es einzeln oder f\xFCge eine Liste von E-Mails ein, die durch ein Komma getrennt sind, und best\xE4tige mit Enter",
      title: "Email-Adressen"
    }
  },
  category: {
    communication: {
      title: "Kommunikation"
    },
    administration: {
      title: "Administration"
    },
    customers: {
      title: "Kunden"
    },
    personal: {
      title: "Personal"
    },
    company: {
      title: "Unternehmen"
    },
    team: {
      title: "Team"
    }
  },
  header: {
    gotoplatform: "Gehe zur Plattform",
    logout: "Abmelden"
  }
};
var double_range_input = {
  year_error_msg: "Bitte geben Sie ein g\xFCltiges Jahr ein",
  end: "Enddatum",
  start: "Startdatum",
  error_msg: "Der Mindestwert sollte kleiner sein als der H\xF6chstwert.",
  clear: "klar",
  max: "max",
  min: "min"
};
var overview = {
  property: {
    mixed: "Institutionelles Eigentum",
    unlisted_fund: "Unlisted fund",
    real_estate_firm: "Immobilienfirma",
    listed_fund: "Gelisteter Fonds",
    foundation: "Stiftungsverm\xF6gen"
  }
};
var source_badge = {
  market_data: "Marktdaten",
  quanthome_data: "Quanthome-Daten",
  estimated: "Gesch\xE4tzt",
  forecasted: "Voraussichtlich",
  computed: "Berechnet",
  expected: "Erwartet",
  listed: "Aufgelistet",
  reported: "Gemeldet",
  official: "Offiziell"
};
var multiple_select = {
  clear: "klar",
  placeholder: {
    search: "Immobilien"
  }
};
var single_select = {
  clear: "klar"
};
var screener_bar = {
  main_button: {
    screener: "Screener",
    launch: "Start"
  }
};
var modal = {
  notifications: {
    subtitle_emailinfo: "Verwalten Sie die E-Mails, die Sie von uns erhalten werden",
    title_emailinfo: "Sie k\xF6nnen jetzt die E-Mail-Einstellungen festlegen",
    title_recoverycode: "Generieren Sie jetzt Ihre Wiederherstellungscodes neu",
    administration_linked: "Sie k\xF6nnen jetzt das Unternehmen {{enterprise}} verwalten.",
    administration_unlinked: "Unternehmen {{enterprise}} wurde von Ihrer Verwaltungsliste entfernt.",
    administration_examination: "Unternehmen {{enterprise}} befindet sich seit einem Monat im Pr\xFCfungsstatus.",
    administration_days_left: "Der Zustand des Unternehmens {{enterprise}} ben\xF6tigt Ihre Aufmerksamkeit: Der {{currentState}}-Zustand hat noch {{daysLeft}} Tage \xFCbrig.",
    title_download: "Herunterladen",
    title_administration: "Administration",
    title: "Benachrichtigungen",
    empty_message: "Keine Benachrichtigungen"
  },
  downloads: {
    not_available: "Die Download-Funktion ist mit Ihrem aktuellen Preisplan nicht verf\xFCgbar.",
    subtitle: {
      file_about_to_expire: "Ihre Datei wird bald ablaufen",
      file_failed: "Ihre Datei ist aufgrund eines Fehlers fehlgeschlagen - Bitte kontaktieren Sie Ihren Administrator.",
      file_expired: "Ihre Datei ist abgelaufen",
      file_ready: "Ihre Datei ist bereit zum Herunterladen",
      file_be_ready_soon: "Ihre Datei wird bald zum Herunterladen bereit sein"
    },
    empty_message: "Keine Downloads",
    title: "Downloads"
  },
  notification: {
    title_download: "Herunterladen"
  },
  history: {
    title: "Geschichte",
    empty_history: "Keine Geschichte"
  },
  preset: {
    empty_message: "Keine Voreinstellungen",
    title: "Voreinstellungen"
  },
  historic: {
    title: "Geschichte"
  },
  leaving: {
    default_text: "Sie haben auf einen Link geklickt, der von Quanthome SA bereitgestellt wurde und Sie au\xDFerhalb der App f\xFChrt. Wir sind nicht mit dieser Website und/oder deren Eigent\xFCmer verbunden. Wir empfehlen dringend, in jedem Fall beim Surfen im Internet niemals Ihre pers\xF6nlichen Informationen preiszugeben, ohne sorgf\xE4ltig zu pr\xFCfen, ob die Website (noch) vertrauensw\xFCrdig ist. Keine Website sollte Sie nach Ihren Quanthome-Anmeldeinformationen fragen, noch wird ein AM nach Ihrem Passwort fragen.",
    registry: {
      title: "Schritte, die im externen Prozess zu befolgen sind"
    },
    title: "Sie sind dabei, die App zu verlassen",
    buttons: {
      proceed: "Fortfahren"
    }
  },
  support: {
    submit_button: "Fragen Sie unseren Support",
    select: {
      placeholder: "W\xE4hle",
      title: "Problem, Nachricht, Bedarf, Idee...",
      need: "Bedarf",
      message: "Nachricht",
      problem: "Problem"
    },
    message: "Nachricht",
    complete_title: "Support / Kontakt",
    error_message: {
      no_message: "Sie m\xFCssen eine Nachricht schreiben",
      too_many_char: "Zu viele Zeichen {{char_nb}} > 500",
      bad_category: "Sie m\xFCssen eine Kategorie ausw\xE4hlen"
    },
    success: {
      message: "Ihr Bericht wurde erfolgreich an unseren Kundenservice gesendet",
      title: "Bericht senden"
    },
    title: "Hilfe",
    upload: "Hochladen"
  }
};
var line_chart = {
  placeholder: {
    no_data_selected: "Bitte w\xE4hlen Sie die anzuzeigenden Daten aus"
  }
};
var empty_chart = {
  message: "No data"
};
var administration = {
  companies: {
    col_name: {
      developer_mode: "Entwicklermodus",
      investor_users: "Investorenbenutzer",
      institutional_users: "Institutionelle Benutzer",
      consumer_users: "Verbraucherbenutzer",
      free_institutional_seat: "Institutionelle Pl\xE4tze \xFCbrig",
      used_institutional_seat: "Institutionelle gebrauchte Sitze",
      total_institutional_seat: "Gesamtzahl der institutionellen Sitze",
      free_investor_seat: "Verf\xFCgbare Investorenpl\xE4tze",
      used_investor_seat: "Investoren gebrauchte Sitze",
      total_investor_seat: "Gesamtzahl der Investorenpl\xE4tze",
      free_consumer_seat: "Verf\xFCgbare Verbrauchersitze",
      used_consumer_seat: "Verbraucher gebrauchte Sitze",
      total_consumer_seat: "Gesamtanzahl der Verbrauchersitze",
      used_all_seats: "Gesamtzahl der genutzten Pl\xE4tze",
      total_all_seats: "Gesamtzahl der Sitze",
      license_use: "Lizenznutzung",
      all_users: "Teammitglieder",
      contact_user_phone: "Kontaktnummer des Benutzers",
      contact_user_email: "Kontakt E-Mail des Benutzers",
      contact_user: "Benutzer kontaktieren",
      related_am: "Verwandter Account-Manager",
      expiration_date: "Vertragsende",
      subscription_start: "Abonnementdatum",
      days_left: "Verbleibende Tage",
      state: "Vertragsart",
      name: "Unternehmen"
    },
    headers: {
      companies_count: "{{count}} Unternehmen",
      member_count: "{{ count }} Mitglieder"
    },
    actions: {
      delete_company: "Unternehmen l\xF6schen",
      update_company_slots: "Aktualisieren Sie die Unternehmensslots",
      update_company: "Unternehmenseinstellungen bearbeiten"
    },
    cells: {
      user_count: "{{ count }} Benutzer"
    },
    teams: "teams",
    table_title: "Unternehmen"
  },
  collaborators: {
    headers: {
      members_count: "{{ count }} Mitglieder"
    }
  },
  breadcrumb: {
    edit: "Editieren",
    create_company: "Erstellen",
    details: "Details",
    companies: "Unternehmen"
  }
};
var treemap = {
  label: {
    label: "Label",
    value: "Wert",
    percentage: "Prozentsatz"
  },
  unit: {
    percentage: "%"
  }
};
var action = {
  label: {
    create_key: "Schl\xFCssel erstellen",
    invite_member: "Mitglied hinzuf\xFCgen",
    create_user: "Benutzer erstellen",
    delete: "L\xF6schen"
  },
  portfolios: {
    name: {
      change_success: {
        message: "Sie haben den Portfolionamen erfolgreich ge\xE4ndert",
        title: "Name wurde ge\xE4ndert"
      }
    },
    remove: {
      loading: {
        message: "Entfernen von {{ asset }}(s) aus Ihrem Portfolio"
      },
      confirmation: {
        question: "Sind Sie sicher, dass Sie das Asset aus diesem Portfolio entfernen m\xF6chten?",
        title: "Aus dem Portfolio entfernen"
      }
    }
  },
  users: {
    remove: {
      confirmation: {
        question: "Sind Sie sicher, dass Sie diese Benutzer entfernen m\xF6chten?"
      }
    }
  },
  companies: {}
};
var pyramid_chart = {
  label: {
    age: "Alter",
    unit: "%",
    percentage: "Prozentsatz",
    woman: "Frau",
    man: "Man",
    group: "Gruppe"
  }
};
var errors = {
  fii: {
    fii_portfolio_duplicated_dwelling_id: "Alle Wohnungen sind bereits in diesem Portfolio enthalten.",
    fii_invalid_dwelling_id: "Keine g\xFCltige Wohnungs-ID.",
    fii_portfolio_duplicated_building_id: "Alle Geb\xE4ude sind bereits in diesem Portfolio.",
    fii_invalid_building_id: "Keine g\xFCltige Geb\xE4ude-ID."
  }
};
var buildings = {
  type: {
    commercial: "Werbung",
    residential: "Wohnung",
    mixed: "Gemischt"
  }
};
var items = {
  charts: {
    gradient: {
      subject: {
        index: "Index",
        indice: "Index"
      }
    }
  },
  array: {
    columns: {
      title: {
        difference: "Unterschied",
        potential: "Gesch\xE4tzt",
        real: "Gemeldet / Aufgelistet"
      }
    }
  }
};
var gradient = {
  quartile_3: "3. Quartil",
  quartile_2: "Median",
  quartile_1: "1. Quartil",
  chart: {
    no_data: "No data"
  },
  label: {
    high: "HOCH",
    low: "GERING"
  }
};
var badge = {
  beta: "Beta-Funktion"
};
var valuations = {
  actions: {
    reset: "Zur\xFCcksetzen"
  }
};
var header = {
  navbar: {
    institutions: "Institutionen",
    map: "Karte",
    funds: "Institutionen",
    portfolios: "Portfolios",
    watchlist: "\xDCberwachungsliste",
    screener: "Immobilien",
    dashboard: "Dashboard"
  },
  dropdown_menu: {
    my_company: "Mein Unternehmen",
    settings: "Einstellungen",
    logout: "Abmelden",
    back_office: "Administration",
    profile: "Mein Profil"
  }
};
var input = {
  select: "ausw\xE4hlen",
  edit: "bearbeiten"
};
var view = {
  switch: {
    cards: "Siehe Karten",
    table: "Siehe Tabelle"
  }
};
var unit = {
  chf_per_m2: "CHF/m\xB2"
};
var policy = {
  cookies: {
    what_are_cookies: "Was sind Cookies?",
    types_list: {
      statistical: "Statistische Cookies: Diese helfen uns zu verstehen, wie Besucher mit unserer Website interagieren, indem sie Informationen \xFCber besuchte Seiten, verbrachte Zeit und eventuelle Probleme, wie Fehlermeldungen, bereitstellen. Dies tr\xE4gt zur Verbesserung der Leistung unserer Website bei.",
      third_party: "Drittanbieter-Cookies: Von Drittanbietern gesetzt, die wir f\xFCr verschiedene Dienste verwenden (z. B. Website-Analyse oder Werbung). QUANTHOME verwendet Google Analytics-Cookies, die Informationen \xFCber Benutzeraktivit\xE4ten bereitstellen, ohne sie zu identifizieren. Google Analytics-Cookies sammeln keine Daten \xFCber Website-Besucher. Weitere Informationen zu Google Analytics finden Sie unter <2>",
      functional: "Funktionale Cookies: Verbessern die Funktionalit\xE4t unserer Website, indem sie zuvor angezeigte Inhalte oder Registrierungsdetails speichern. Diese Cookies erm\xF6glichen personalisierte Inhalte und sparen Ihnen Zeit, da Sie sich bei zuk\xFCnftigen Besuchen nicht erneut registrieren m\xFCssen. Wir verwenden auch Cookies f\xFCr Analysen und Werbung.",
      essential: "Wesentliche Cookies: Notwendig f\xFCr den Betrieb unserer Website."
    },
    definition: "Cookies sind kleine Textdateien, die von den von Ihnen besuchten Websites auf Ihrem Computer abgelegt werden. Sie werden h\xE4ufig verwendet, um sicherzustellen, dass Websites funktionieren oder effizienter arbeiten, und um Informationen an die Website-Betreiber bereitzustellen. Cookies k\xF6nnen automatisch Daten auf Ihrem Ger\xE4t verarbeiten und/oder Daten \xFCber Sie an den Website-Betreiber oder Dritte \xFCbertragen. Sie k\xF6nnen die Cookies der Website verwalten.",
    more_information: "F\xFCr weitere Informationen besuchen Sie <2>",
    usage: "Warum und wie verwenden wir Cookies?",
    purpose: "Wir verwenden Cookies, um die Benutzerfreundlichkeit und Funktionalit\xE4t unserer Website zu verbessern und um Ihr Surferlebnis zu personalisieren oder zu verbessern, damit Ihr n\xE4chster Besuch angenehmer wird.",
    types_overview: "Die Cookies, die wir verwenden, umfassen:",
    consent: "Wir bitten um Ihre ausdr\xFCckliche Zustimmung zur Verwendung von Cookies beim Zugriff auf die Website. W\xE4hrend Sie dieser Richtlinie zustimmen, k\xF6nnen Sie Cookies ablehnen. Sie k\xF6nnen Cookies jederzeit \xFCber die Einstellungen Ihres Browsers oder Ihres elektronischen Ger\xE4ts verwalten. Bitte beachten Sie jedoch, dass einige Cookies f\xFCr die Funktion der Website unerl\xE4sslich sind und ihre Verwendung m\xF6glicherweise beeintr\xE4chtigt wird, wenn Cookies abgelehnt oder blockiert werden.",
    types: "Welche Arten von Cookies werden verwendet?"
  },
  data_correction: "Sie k\xF6nnen uns kontaktieren, um Ihre Daten zu erfahren, deren Richtigkeit zu \xFCberpr\xFCfen und deren Vervollst\xE4ndigung, Aktualisierung, Berichtigung oder L\xF6schung zu beantragen, wie es das Gesetz, insbesondere Art. 32 LPD, erlaubt.",
  rights_overview: "Sie haben das Recht, die L\xF6schung, Berichtigung oder Aktualisierung der Daten, die wir \xFCber Sie verarbeiten, zu erfahren und zu beantragen.",
  type_of_data: "Welche Art von Daten stellen Sie QUANTHOME zur Verf\xFCgung?",
  retention_request: "Sie k\xF6nnen QUANTHOME bitten, Ihre Daten f\xFCr eine potenzielle zuk\xFCnftige Registrierung zu speichern oder um weiterhin unseren Newsletter zu erhalten.",
  rights_exercise: "F\xFCr weitere Details oder um Ihre Rechte auszu\xFCben, kontaktieren Sie uns unter <2>",
  legal_processing_objection: "Sie haben auch das Recht, zu verlangen, dass wir die spezifische Verarbeitung von Daten, die in Verletzung des Gesetzes erlangt oder verarbeitet wurden, einstellen und der Verarbeitung von Daten aus legitimen Gr\xFCnden widersprechen.",
  title: "Datenschutzrichtlinie",
  eea_rights_list: {
    objection: "Recht, der Verarbeitung Ihrer Daten zu widersprechen und die Einwilligung zu widerrufen (Art. 21 DSGVO),",
    processing_restriction: "Recht auf Einschr\xE4nkung der Verarbeitung auf Anfrage (Art. 18 DSGVO),",
    complaint: "Recht, eine Beschwerde bei einer Beh\xF6rde an Ihrem gewohnten Wohn- oder Arbeitsort oder dort, wo ein Versto\xDF stattgefunden hat, einzureichen (Art. 77 DSGVO),",
    forgotten: "Recht auf Vergessenwerden (Art. 17 DSGVO),",
    rectification: "Recht auf Berichtigung (Art. 16 DSGVO),",
    access: "Recht auf Zugang zu Ihren Daten (Art. 15 DSGVO),",
    automated_decisions: "Recht, nicht automatisierten Einzelentscheidungen unterworfen zu werden (ein Verfahren, das QUANTHOME nicht verwendet)."
  },
  data_list: {
    phone_number: "Ihre Telefonnummer",
    company_name: "Der Name oder die Firmenbezeichnung des Unternehmens, das Sie vertreten",
    first_name: "Ihr Vorname",
    last_name: "Ihr Nachname",
    email_address: "Ihre E-Mail-Adresse"
  },
  additional_information: "Alle pers\xF6nlichen Informationen, die Sie spontan in E-Mail-Austauschen mit uns teilen; Alle anderen Daten, die durch Cookies und den Webserver gesammelt werden. Weitere Informationen \xFCber automatisch gesammelte Daten finden Sie in Artikel 10 der Richtlinie.",
  data_retention: "Wie lange speichert QUANTHOME Ihre Daten?",
  data_sharing: "Mit wem k\xF6nnte QUANTHOME Ihre Daten teilen?",
  anonymization_use: "Wir k\xF6nnten Ihre Daten verarbeiten, um identifizierbare Informationen zu entfernen (Anonymisierung), und dann diese anonymen Daten f\xFCr Zwecke zu verwenden, die in der Richtlinie nicht aufgef\xFChrt sind (z. B. vergleichende Analysen, Kampagnenzielgruppen oder Entwicklung und Vermarktung neuer Dienstleistungen)",
  data_usage: {
    account_creation: "Ihr Name, Nachname und, falls zutreffend, der Name Ihres Unternehmens, um Ihr Konto zu erstellen und Ihnen den Zugang zur Website zu erm\xF6glichen und Produkte zu bestellen. Dies ist vertraglich erforderlich.",
    legal_age_verification: "Ihr Geburtsdatum stellt sicher, dass Sie das gesetzliche Mindestalter haben, um Vertr\xE4ge mit uns abzuschlie\xDFen. Dies basiert auf unserem berechtigten Interesse.",
    cookies_analytics: "Die von Site-Cookies \xFCbermittelten Daten dienen internen Analysen und Werbung. Dies basiert auf unserem berechtigten Interesse und Ihrer Zustimmung, wenn dies gesetzlich erforderlich ist.",
    legal_compliance: "Alle \xFCbermittelten Daten k\xF6nnen verarbeitet werden, um rechtlichen Verpflichtungen und anderen gesetzlichen Anforderungen zu entsprechen",
    contact_information: "Ihre Adresse, E-Mail und Telefonnummer erm\xF6glichen es uns, Sie zu kontaktieren. Diese sind f\xFCr den Verkauf von Produkten erforderlich. Wenn sie f\xFCr Newsletter oder Werbekommunikation verwendet werden, erfolgt dies auf Grundlage Ihrer Zustimmung.",
    payment_verification: "Ihre Bankdaten validieren Ihre Zahlung f\xFCr Produkte. Dies ist vertraglich erforderlich."
  },
  data_handling: "Wie geht QUANTHOME mit Ihren Daten um?",
  cookies_section: "Cookies",
  policy_date: "Diese Datenschutzrichtlinie (die 'Richtlinie') ist datiert auf den 30. September 2023. Sie kann regelm\xE4\xDFig \xFCberpr\xFCft werden (siehe Artikel 11).",
  hosting_details: "Daten, die von QUANTHOME gesammelt werden, werden in der Schweiz gehostet und von Infomaniak verarbeitet",
  data_provision_consequences: "Sie sind nicht gesetzlich oder vertraglich verpflichtet, uns Daten zur Verf\xFCgung zu stellen. Wenn Sie jedoch bestimmte Daten nicht bereitstellen oder die in diesem Artikel 9 genannten Rechte aus\xFCben, k\xF6nnte dies dazu f\xFChren, dass wir Ihnen unsere Produkte, einschlie\xDFlich des Zugangs zur Website, nicht anbieten k\xF6nnen.",
  law_compliance: "Wir verarbeiten Ihre Daten in \xDCbereinstimmung mit den geltenden Gesetzen, insbesondere dem Schweizer und dem Europ\xE4ischen Datenschutzrecht, und ergreifen Ma\xDFnahmen, um unbefugten Zugriff, Offenlegung, Ver\xE4nderung oder Zerst\xF6rung zu verhindern.",
  sharing_list: {
    corporate_events: "Bei Fusionen, \xDCbernahmen oder Umstrukturierungen oder m\xF6glichen Insolvenzverfahren k\xF6nnte Ihre Daten als Verm\xF6genswert von QUANTHOME an Dritte \xFCbertragen werden. Wir werden uns bem\xFChen, Sie im Voraus \xFCber solche \xDCbertragungen zu informieren, k\xF6nnen jedoch kein Opt-out garantieren.",
    service_execution: "Mit allen Dritten, die f\xFCr die Durchf\xFChrung des Dienstes notwendig sind (z. B. Mitarbeiter, verbundene Unternehmen, Zahlungsanbieter, Logistikunternehmen, unserem Hosting-Anbieter und IT-Personal).",
    legal_requirements: "Mit Dritten, die gesetzlich erforderlich sind aufgrund einer Anordnung von gerichtlichen oder staatlichen Beh\xF6rden oder zur Einhaltung unserer gesetzlichen Verpflichtungen."
  },
  why_collect_data: "Warum sammelt QUANTHOME diese Daten?",
  data_deletion_request: "Wenn Sie m\xF6chten, dass wir Ihre Daten aus unseren Systemen l\xF6schen, senden Sie eine Anfrage an den untenstehenden Kontakt, der wir nachkommen werden, es sei denn, wir m\xFCssen Ihre Daten aus rechtlichen Gr\xFCnden oder einem anderen legitimen Zweck aufbewahren.",
  data_hosting: "Wo wird Ihre Daten gehostet?",
  what_is_personal_data: "Was sind personenbezogene Daten?",
  security_measures: "QUANTHOME bem\xFCht sich, die Sicherheit Ihrer Daten zu gew\xE4hrleisten und unbefugten Zugriff, Verwendung, Modifikation oder Offenlegung zu verhindern.",
  updates: "Wir behalten uns das Recht vor, \xC4nderungen und Korrekturen an diesen Bestimmungen vorzunehmen. Bitte \xFCberpr\xFCfen Sie diese Seite regelm\xE4\xDFig auf Updates und neue Informationen.",
  processing_methods: "Wir k\xF6nnen Ihre Daten entweder automatisch oder manuell verarbeiten und dabei stets geeignete Sicherheitsma\xDFnahmen umsetzen.",
  personal_data_definition: "Personenbezogene Daten ('Daten') beziehen sich auf Informationen, die sich auf eine Person beziehen. Solche Daten k\xF6nnen Sie entweder direkt identifizieren (z. B. Ihr Name, Ihre E-Mail-Adresse oder Ihre Sozialversicherungsnummer) oder indirekt, indem verschiedene Informationen \xFCber Sie kombiniert werden (z. B. die IP-Adresse Ihres Computers, Ihre Kreditkarte oder Ihre Telefonnummer).",
  transfer_consent: "Durch den Zugriff auf die Website und das Teilen von Daten stimmen Sie ausdr\xFCcklich solchen \xDCbertragungen zu.",
  retention_period: "QUANTHOME bewahrt Ihre Daten nur so lange auf, wie es notwendig ist, typischerweise 3 Jahre nach Ihrem letzten Kontakt mit QUANTHOME. Aufgrund unserer gesetzlichen Verpflichtungen m\xFCssen einige Daten, insbesondere solche, die unsere vertragliche Beziehung betreffen, mindestens 10 Jahre lang aufbewahrt werden.",
  consent_withdrawal: "Wenn wir auf Ihre Einwilligung angewiesen sind, um Ihre Daten zu verarbeiten, werden wir Ihre kostenlose und spezifische Einwilligung einholen und klare Anweisungen geben. Sie k\xF6nnen Ihre Einwilligung jederzeit widerrufen. Sie haben auch das Recht, die \xDCbertragbarkeit Ihrer automatisch verarbeiteten Daten auf der Grundlage Ihrer Einwilligung oder vertraglichen Notwendigkeit zu verlangen.",
  credential_safety: "Um die Vertraulichkeit Ihrer Daten zu sch\xFCtzen, m\xFCssen Sie Ihre Anmeldeinformationen sicher aufbewahren und sie niemandem offenbaren. Sie sind verantwortlich f\xFCr jede Nutzung der auf der Website angebotenen Dienste mit Ihren Anmeldeinformationen.",
  consent: "Wenn die oben genannten Gr\xFCnde nicht als legitim angesehen werden, stimmen Sie der Verarbeitung der Daten zu den genannten Zwecken zu, indem Sie diese Richtlinie akzeptieren.",
  third_party_security: "Wir bem\xFChen uns auch sicherzustellen, dass Dritte, mit denen Ihre Daten geteilt werden k\xF6nnten (wie oben beschrieben), diese sicher behandeln und deren Vertraulichkeit und Integrit\xE4t wahren.",
  company_info: "Quanthome SA, im Folgenden als 'QUANTHOME', 'wir' oder 'unser' bezeichnet, ist eine Aktiengesellschaft, die im Handelsregister des Kantons Waadt eingetragen ist und ihren Sitz in der Avenue de Mon-Repos 24, 1005 Lausanne, Schweiz hat.",
  sharing_overview: "QUANTHOME kann Ihre Daten wie folgt teilen:",
  anonymization_alternative: "Statt Ihre Daten zu zerst\xF6ren oder zu l\xF6schen, k\xF6nnte QUANTHOME sie dauerhaft anonymisieren, wodurch eine Identifizierung unm\xF6glich wird.",
  data_processing_basis: "Wir verarbeiten Ihre Daten auf der Grundlage der nachstehenden legitimen Gr\xFCnde oder auf der Grundlage Ihrer Einwilligung:",
  access_right: "Sofern nicht anders durch geltendes Recht vorgesehen, haben Sie das Recht zu erfahren, ob wir Daten, die Sie betreffen, verarbeiten und Ihr Zugriffsrecht gem\xE4\xDF Art. 25 LPD auszu\xFCben.",
  security_breach_protocol: "Bitte informieren Sie uns umgehend \xFCber jeglichen verd\xE4chtigen oder best\xE4tigten Missbrauch Ihrer Anmeldedaten. Denken Sie daran, dass keine Sicherheitsma\xDFnahme narrensicher ist.",
  non_identifying_data_use: "Weitere nicht identifizierende Daten, einschlie\xDFlich aggregierter oder anonymisierter Daten, k\xF6nnen verwendet werden, um Dienstleistungen zu verwalten, die Kundenbeziehungen zu verbessern, technische Probleme zu diagnostizieren und aggregierte demografische Informationen zu sammeln.",
  agreement: "Durch den Zugriff auf die Website <1>",
  eea_rights: "Wenn Sie ein EWR-Bewohner sind, haben Sie speziell die folgenden Rechte:",
  data_provided: "Daten, die QUANTHOME automatisch sammelt, wenn Sie auf die Website zugreifen oder die Sie freiwillig bei der Erstellung eines Kontos oder sp\xE4ter w\xE4hrend der Bestellungen bereitstellen (mit einem Sternchen gekennzeichnete Felder sind erforderlich, und zus\xE4tzliche Felder k\xF6nnen optional bereitgestellt werden):",
  your_rights: "Welche Rechte haben Sie bez\xFCglich Ihrer Daten?",
  data_protection: "Wie sch\xFCtzt QUANTHOME Ihre Daten?",
  terms_invitation: "Wir laden Sie auch ein, unsere Nutzungsbedingungen und unsere Endbenutzer-Lizenzvereinbarung zu \xFCberpr\xFCfen",
  inquiries: "F\xFCr Anfragen zu dieser Richtlinie oder zur Nutzung Ihrer Daten kontaktieren Sie uns bitte unter <2>",
  eea_representative: "Unser Vertreter im EWR ist [auszuf\xFCllen]. Die folgenden Richtlinien gelten f\xFCr alle Daten, die Sie QUANTHOME zur Verf\xFCgung stellen.",
  introduction: "Ihre Privatsph\xE4re ist uns wichtig. Als Datenverantwortlicher verpflichtet sich Quanthome SA, die pers\xF6nlichen Daten, die Sie mit uns teilen, transparent und in \xDCbereinstimmung mit den geltenden Gesetzen zu verarbeiten.",
  international_transfers: "In einigen F\xE4llen, insbesondere aus technischen Gr\xFCnden, k\xF6nnen Ihre Daten au\xDFerhalb der Schweiz oder der Europ\xE4ischen Union \xFCbertragen werden. In solchen F\xE4llen gew\xE4hrleisten Dritte angemessene Ma\xDFnahmen, die sicherstellen, dass die gesetzlichen Anforderungen und der Schutz Ihrer Rechte erf\xFCllt sind.",
  legal_updates: "Aktualisierung rechtlicher Informationen."
};
var login = {
  error: "Fehler beim Protokollieren"
};
var watchlist = {
  buildings: {
    empty: "Keine Geb\xE4ude-Beobachtungsliste"
  },
  dwellings: {
    empty: "Keine Wohnungsbeobachtungsliste"
  }
};
var download = {
  action_label: "CSV herunterladen",
  request_created: "Anfrage erstellt."
};
var profile_train = {
  tooltip: {
    more: "{{count}} mehr"
  }
};
var impressum = {
  mail: "E-Mail-Adresse:",
  address: "Postanschrift: Avenue Mon-Repos 24, 1005 Lausanne",
  text: "Impressum Quanthome SA nutzt die Website "
};
var notification = {
  terminated_session: {
    message: "Sie k\xF6nnten mit einem anderen Ger\xE4t verbunden sein oder die Sitzungslebensdauer wurde erreicht.",
    title: "Sitzung beendet"
  }
};
var paywall = {
  button: {
    logout: "Abmelden"
  },
  subtitle: "Bitte kontaktieren Sie Ihren Account-Manager.",
  message: {
    error: "Es gibt einen Fehler mit Ihrem Abonnement.",
    no_slot: "Sie haben derzeit kein Recht, die Anwendung zu nutzen.",
    slot_out_of_scope: "Dieses Feature ist nicht in Ihren Berechtigungspl\xE4tzen enthalten.",
    out_of_scope: "Dieses Feature ist nicht in Ihrem Unternehmensabonnement enthalten.",
    end_trial: "Ihr Test ist abgelaufen."
  }
};
var dashboard = {
  trend: {
    title: "Schweizer Markttrend: Nettomiete pro m\xB2"
  },
  rent: {
    title: "Miete pro m\xB2 pro Kanton"
  },
  indicators: {
    title: "Indikatoren",
    reference_interest_rate: "Referenzzinssatz",
    switzerland_vacancy_rate: "Schweizer Leerstandsquote",
    ipc_lik: "CPI Verbraucherpreisindex (IPC/LIK)",
    snb_policy_rate: "Politikrate der Schweizerischen Nationalbank"
  },
  indices: {
    title: "Indizes",
    construction_price_index_total: "Schweizer Bauindex - Gesamt",
    construction_price_index_civil_engineering: "Schweizer Bauindex - Tiefbau",
    construction_price_index_building: "Schweizer Bauindex - Geb\xE4ude",
    swiss_residential_property_price: "Schweizer Wohnimmobilienpreisindex"
  },
  error: {
    feature: {
      rights: "Sie haben keinen Zugang zu dieser Funktion"
    }
  },
  empty: {
    funds: "Sie haben keine Mittel",
    portfolios: "Sie haben keine Portfolios"
  }
};
var widget = {
  side_menu: {
    country: "Land",
    canton: "Kanton",
    municipality: "Gemeinde"
  }
};
var status = {
  resend: "Erneut senden",
  not_complete: "Nicht abgeschlossen",
  completed: "Abgeschlossen"
};
var option = {
  no_plan: "Kein Plan",
  sudo: "Sudo",
  consumer: "Verbraucher",
  investor: "Investor",
  institutional: "Institutionell",
  am: "Account-Manager",
  admin: "Admin",
  user: "Benutzer",
  manager: "Manager",
  owner: "Eigent\xFCmer",
  triage: "Triage"
};
var boarding = {
  suggested_otp: "Vorgeschlagene OTP-App:"
};
var potfolios = {
  sections: {
    assets: {
      title: "Inventar"
    },
    social: {
      title: "Sozial"
    },
    environmental: {
      title: "Umwelt"
    },
    financials: {
      title: "Finanzen"
    },
    description: {
      title: "<text>Beschreibung</text>"
    }
  },
  tiles: {
    charges: {
      title: "Geb\xFChren"
    },
    evolution: {
      title: "Evolution"
    },
    revenue: {
      title: "Umsatz"
    }
  },
  widget: {
    charges: {
      title: "Geb\xFChren"
    },
    revenue: {
      title: "Umsatz"
    }
  }
};
var inputs = {
  placeholder: {
    input_name: "Input-Name",
    preset_name: "Voreinstellung Name",
    choose_option: "W\xE4hlen Sie eine Option"
  }
};
var entity_selector = {
  search: "Suche",
  button: {
    save: "\xC4nderungen speichern",
    cancel: "Stornieren"
  }
};
var users = {
  role: {
    user: "Benutzer"
  },
  roles: {
    triage: "Triage",
    owner: "Eigent\xFCmer"
  }
};
var general = {
  app_wall: {
    redirect: {
      message: "Sie haben derzeit keinen Zugriff auf die App. Wir werden Sie zu den Einstellungen umleiten\u2026"
    }
  }
};
var password = "Neues Passwort";
var traits = {
  contact: {
    office_phone: "Gesch\xE4ftstelefonnummer"
  },
  email: "Email-Adresse",
  name: {
    last: "Nachname",
    first: "Vorname"
  }
};
var search = {
  title: "Suchergebnis",
  nothing_found: "Nichts gefunden",
  placeholder: "Adresse, EGID, Name, Fonds, ISIN..."
};
var de_default = {
  funds,
  panel_selection,
  units,
  table_header,
  global,
  fund,
  license_agreement,
  footer,
  table_actions,
  table,
  boarding_page,
  asset,
  preset,
  tile,
  error,
  companies,
  balance_sheet,
  actions,
  common,
  income_statement,
  asset_page,
  portfolios,
  portfolio,
  candle_stick_chart,
  screener,
  setting,
  unsaved_screener,
  management,
  double_range_input,
  overview,
  source_badge,
  multiple_select,
  single_select,
  screener_bar,
  modal,
  line_chart,
  empty_chart,
  administration,
  treemap,
  action,
  pyramid_chart,
  errors,
  buildings,
  items,
  gradient,
  badge,
  valuations,
  header,
  input,
  view,
  unit,
  policy,
  login,
  watchlist,
  download,
  profile_train,
  impressum,
  notification,
  paywall,
  dashboard,
  widget,
  status,
  option,
  boarding,
  potfolios,
  inputs,
  entity_selector,
  users,
  general,
  password,
  traits,
  search
};

export {
  funds,
  panel_selection,
  units,
  table_header,
  global,
  fund,
  license_agreement,
  footer,
  table_actions,
  table,
  boarding_page,
  asset,
  preset,
  tile,
  error,
  companies,
  balance_sheet,
  actions,
  common,
  income_statement,
  asset_page,
  portfolios,
  portfolio,
  candle_stick_chart,
  screener,
  setting,
  unsaved_screener,
  management,
  double_range_input,
  overview,
  source_badge,
  multiple_select,
  single_select,
  screener_bar,
  modal,
  line_chart,
  empty_chart,
  administration,
  treemap,
  action,
  pyramid_chart,
  errors,
  buildings,
  items,
  gradient,
  badge,
  valuations,
  header,
  input,
  view,
  unit,
  policy,
  login,
  watchlist,
  download,
  profile_train,
  impressum,
  notification,
  paywall,
  dashboard,
  widget,
  status,
  option,
  boarding,
  potfolios,
  inputs,
  entity_selector,
  users,
  general,
  password,
  traits,
  search,
  de_default
};
